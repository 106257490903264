import React, { FC, useCallback, useEffect } from "react";
import _ from "lodash";
import { Typography } from "design_system/src";

import newVehicleImg from "static/images/vehicles/new-vehicle.png";
import seminewVehicleImg from "static/images/vehicles/seminew-vehicle.png";
import activeLinesImg from "static/images/active-lines.png";
import inactiveLinesImg from "static/images/inactive-lines.png";

import useVehicles from "../../../hooks/useVehicles";

import "./VehicleConditionFilter.scss";

interface Props {
    updateCatalogFilters: any;
}

// eslint-disable-next-line no-shadow
export enum VEHICLE_CONDITIONS {
    NEW = "new",
    USED = "used",
}

// eslint-disable-next-line no-shadow
enum VEHICLE_CONDITIONS_ES {
    NEW = "nuevas",
    USED = "seminuevas",
}

export const translateVehicleCondition = (value: string) => {
    const keyValue = Object.keys(VEHICLE_CONDITIONS).find((key) => _.get(VEHICLE_CONDITIONS, key) === value);
    return keyValue ? _.get(VEHICLE_CONDITIONS_ES, keyValue).toUpperCase() : null;
};

export const VehicleConditionFilter: FC<Props> = ({ updateCatalogFilters }) => {
    const { selectedFilters: { newVehicle } = {} } = useVehicles();
    const [vehicleCondition, setVehicleCondition] = React.useState(() => {
        return newVehicle ?? undefined;
    });

    useEffect(() => {
        updateCatalogFilters({"newVehicle": vehicleCondition});
    }, [vehicleCondition]);

    useEffect(() => {
        if (newVehicle === undefined) { setVehicleCondition(newVehicle ?? undefined); }
    }, [newVehicle]);

    const renderCardButton = (condition: VEHICLE_CONDITIONS) => {
        return (
            <button
                type="button"
                className={`flex_grow_1 card${vehicleCondition === condition ? " active": ""}`}
                onClick={() => setVehicleCondition(condition)}
            >
                <div className="card_imageSection">
                    <img
                        src={vehicleCondition === condition ? activeLinesImg : inactiveLinesImg}
                        alt="victory"
                        className="card_image card_linesImg"
                    />
                    <img
                        src={condition === VEHICLE_CONDITIONS.NEW ? newVehicleImg : seminewVehicleImg}
                        alt="victory"
                        className="card_image card_vehicleImg"
                    />
                </div>
                <Typography
                    scale="large"
                    scaleMobile="medium"
                    weight="600"
                    className="text_uppercase flex_grow_1"
                >{condition === VEHICLE_CONDITIONS.NEW ? "Motos nuevas" : "Motos seminuevas"}</Typography>
            </button>
        );
    };

    return (
        <div
            className="display_flex flex_col_reverse_mobile w_100_per"
            style={{
                alignItems: "center",
                gap: 8,
                paddingLeft: 12,
                paddingRight: 12,
                marginTop: 25,
            }}
        >
            {renderCardButton(VEHICLE_CONDITIONS.USED)}
            {renderCardButton(VEHICLE_CONDITIONS.NEW)}
        </div>
    );
};
