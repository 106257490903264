import React from "react";
import { Typography } from "design_system/src";
import { StepOneA, StepOneB, StepThree, StepTwoA, StepTwoB } from "views/ozonFly/steps";

type StepProps = {
    workDigitalPlatforms?: boolean;
};

export const IneStep = () => {
    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">INE legible por ambas caras</Typography>
        <StepOneA location="MyAccount"/>
    </div>;
};

export const SelfieStep = () => {
    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Selfie</Typography>
        <StepOneB location="MyAccount"/>
    </div>;
};

export const AddressStep = () => {
    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Comprobante de domicilio</Typography>
        <StepTwoA location="MyAccount"/>
    </div>;
};

export const IncomesStep: React.FC<StepProps> = ({ workDigitalPlatforms }) => {
    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Comprobante de  ingresos</Typography>
        <StepTwoB location="MyAccount" workDigitalPlatforms={workDigitalPlatforms}/>
    </div>;
};

export const ReferencesStep = () => {
    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Referencias</Typography>
        <Typography className="text_center" weight="400" scale="small" component="p">Ingresa los datos de tus referencias</Typography>
        <StepThree location="MyAccount"/>
    </div>;
};
