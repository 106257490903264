/* eslint-disable arrow-body-style */
import { Button } from "design_system/src";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import photo1 from "static/brandSection/bajaj-01.svg";
// import photo2 from "static/brandSection/benelli-01.svg";
// import photo3 from "static/brandSection/dinamo-01.svg";
// import photo4 from "static/brandSection/hero-01.svg";
// import photo6 from "static/brandSection/izuka-01.svg";
import photo1 from "static/brandSection/keeway-01.png";
import photo2 from "static/brandSection/veloci-01.png";
import photo3 from "static/brandSection/italika-01.png";
import photo4 from "static/brandSection/vento.png";

import { ReactComponent as ChevronRight } from "design_system/src/static/icons/chevron-right.svg";

// eslint-disable-next-line import/no-unresolved
import "./brandSection.scss";

export const BrandSection = () => {
  const navigate = useNavigate();
  const brands = [photo1, photo2, photo3, photo4];
  return (
    <div className="h_100_per">
      <style>
        {`@keyframes slide {
      0% {
        transform: translate3d(0, 0, 0);
      }
      100% {
        transform: translate3d(-${
          brands.length * 30
        }px, 0, 0); /* The image width */
      }`}
      </style>
      <div className="w_100_per_desktop h_100_per_desktop h_100_pr_mobile display_flex flex_center_desktop brandSection m_y_xl flex_col_mobile flex_align_center_mobile">
        <div className="display_flex flex_gap_xl flex_center flex_1 brands">
          {brands.map((brand, index) => {
            return (
              <figure className="m_none" key={index}>
                <img
                  src={brand}
                  key={`brands-${index}`}
                  alt="brand"
                  className="h_xl"
                />
              </figure>
            );
          })}
        </div>
        <div className="display_flex_desktop flex_align_center flex_0">
          <Link to="/catalogo" className="custom_a">
            <Button
              type="button"
              variant="ghost"
              scale="small"
              icon={<ChevronRight />}
              orientation="right"
              className="display_flex flex_center"
            >
              ver todas las marcas
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
