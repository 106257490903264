/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";

import Swipercore, { Navigation, Pagination, Autoplay } from "swiper";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// eslint-disable-next-line import/extensions
import "swiper/css/navigation";

// eslint-disable-next-line import/extensions
import "swiper/css/pagination";

// css
import "./banner.scss";

// imgs

import AlliesSection from "components/allies/AlliesSection";

// import DiscountSection from "components/discountSection/DiscountSection";
import { HubsSection } from "components/hocs/bannerMX/hubs/HubsSection";

import { useDispatch } from "react-redux";
import { fetchRecommendedThunk } from "store/actions/recommended";
import HeroSection from "./heroSection/HeroSection";
import { BrandSection } from "./brandSection/BrandSection";
import { OneThousandSection } from "./oneThousandSection/OneThousandSection";
import PreCatalogSection from "./preCatalog/PreCatalogSection";
import { SectionBoost } from "./sectionBoost/SectionBoost";
import { fetchVehiclesThunk, updateSelectedFilters } from "../../../store/actions/vehicles";
import useCurrentCity from "../../../hooks/useCurrentCity";
import MediaSection from "./media/MediaSection";
import { Recommended } from "../recommended/Recommended";

export const Bannermx = () => {
  Swipercore.use([Navigation, Pagination, Autoplay]);
  const dispatch = useDispatch();
  const city = useCurrentCity();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchVehiclesThunk({ filters: { city, limit: 16 } }));
  }, [dispatch, city]);

  return (
    <>
      <OneThousandSection />
      <AlliesSection />
      {/* <Recommended /> */}
      <SectionBoost />
      <PreCatalogSection />
      <HubsSection />
      <MediaSection />
      <HeroSection />
    </>
  );
};
