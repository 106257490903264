/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

export enum Device {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

const useDevice = () => {
  const [device, setDevice] = useState<Device>(Device.DESKTOP);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (width < 600) {
      setDevice(Device.MOBILE);
    } else {
      setDevice(Device.DESKTOP);
    }
  }, [width, height]);

  return {
    device,
    isMobile: device === Device.MOBILE,
    isDesktop: device === Device.DESKTOP,
  };
};

export default useDevice;
