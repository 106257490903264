/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Tag, Button, Typography } from "design_system/src";
import useVehicles from "hooks/useVehicles";
import { SelectedFilters } from "store/reducers/vehiclesReducer";
import { translateColorValues } from "helpers/translateColorValues";
import { translateVehicleCondition } from "../VehicleConditionFilter/VehicleConditionFilter";

interface Props {
  updateCatalogFilters: any;
}

export const FilterTagList: React.FC<Props> = ({ updateCatalogFilters }) => {
  const { selectedFilters } = useVehicles();
  const navigate = useNavigate();

  const filterList = useMemo(
    () => selectedFilters
      ? [...Object.keys(selectedFilters)]
        .filter((key: string) => key !== "limit" && key !== "city" && key !== "page" && key !== "sort")
        .map((key) => ({key, value: selectedFilters[key as keyof SelectedFilters]}))
        .map(({key, value}) => {
          let formatedValue = `${value}`;
          if (key === "color") {
            formatedValue = translateColorValues(selectedFilters)[key as keyof SelectedFilters];
          }
          if (key === "cylinders") {
            formatedValue = `${value} CC`;
          }
          if (key === "mileages") {
            const range = value as number[];
            formatedValue = range[1] ? `De ${range[0]}Km a ${range[1]}Km` : `Más de ${range[0]}Km`;
          }
          if (key === "salePrice") {
            const range = value as number[];
            formatedValue = range[1]
              ? range[0] === 1
                ? `Menos de $${range[1]}`
                :`De $${range[0]} a $${range[1]}`
              : `Más de $${range[0]}`;
          }
          if (key === "newVehicle") {
            formatedValue = translateVehicleCondition(value as string);
          }

          return {key, value: formatedValue};
        })
      : [],
    [selectedFilters]
  );

  return (
    <div className="display_flex flex_wrap flex_gap_xs">
      {filterList.map(({ key, value}, index) => (
        <Tag
          key={index}
          value={value}
          icon={null}
          className="bg-white"
          onDelete={() => updateCatalogFilters({ [key] : undefined})}
        />
      ))}
      {filterList.length > 1 && <Button
        onClick={() => {
          navigate("/catalogo");
          updateCatalogFilters({ city: selectedFilters?.city, limit: selectedFilters?.limit}, false);
        }}
        variant="link"
        scale="small"
      >
        <Typography
          weight="400"
          className="text_secondary_600"
          scale="small"
        >
          Borrar todos
        </Typography>
      </Button>}
    </div>
  );
};
