/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { ITVehicle, ListVehicles, TVehicle} from "models/vehicle.interface";
import { fetchVehiclesGraphql } from "apolloClient";
import {Cities} from "../store/reducers/cityReducer";
import {serialize} from "./serialize";
import {PandaboardApiInstance} from "./api";
import {cleanBrands, cleanCylindersFilters, cleanModels, cleanRangeValues, cleanYears} from "./formatFilters";
import {colorOptions} from "./typesVehicles";
import {shuffleArray} from "./ common";

interface IColorOptions {
  value: string;
  label: string;
}

export interface VehiclesFilters {
  brands: string[];
  cylinder_Capacities: string[];
  years: number[];
  city: Cities;
  milageRange?: string[]
  priceRange?: string[]
  sort?: string;
  colors?: IColorOptions[];
  ranges?: number[];
  models?: string[];
  limit: number;
  weeks?: number[];
}

export interface GetVehiclesResponse {
  vehicles: TVehicle[];
  filters: Omit<VehiclesFilters, "city">;
}

export const CACHE_TIME =  10 * 1000; // 10 Minutes


export const getVehicles = async (filters: Partial<VehiclesFilters>): Promise<ListVehicles | null> => {
  try {
    const {getAllVehicles} = await fetchVehiclesGraphql(filters);
    const cylinderCapacities = cleanCylindersFilters(getAllVehicles.filters.cc);
    const years = cleanYears(getAllVehicles.filters.years);
    const models = cleanModels(getAllVehicles.filters.models);
    const brands = cleanBrands(getAllVehicles.filters.models);
    const milageRange = cleanRangeValues(getAllVehicles.filters.mileageRange);
    const priceRange = cleanRangeValues(getAllVehicles.filters.priceRange, false);
    const colors:IColorOptions[] = colorOptions.filter(({ value }) => getAllVehicles.filters.colors.includes(value));

    const vehicleInstace = getAllVehicles.data.map((item: ITVehicle) => new TVehicle(item));

    const fetchVehicleResponse = {
      vehicles: vehicleInstace,
      filters: {
        ...(getAllVehicles.filters),
        brands,
        cylinder_Capacities: cylinderCapacities,
        models,
        years,
        colors,
        milageRange,
        priceRange
      },
      page: getAllVehicles.page,
      pages: getAllVehicles.pages,
      count: getAllVehicles.count,
    } as ListVehicles;


    return fetchVehicleResponse;
  } catch (e:any) {
    console.log(e);
    return null;
  }
};

export const getVehicle = (id: string): Promise<TVehicle | null> =>
  PandaboardApiInstance
    .get(`vehicle/${id}`)
    .then((response) => new TVehicle(response.data))
    .catch(() => null);
