import React, { FC, useEffect, useState } from "react";
import Typography from "../Typography/Typography";

const isEqual = (someDate: Date | null, otherDate: Date | null) =>
    someDate && otherDate &&
    someDate.getDate() === otherDate.getDate() &&
    someDate.getMonth() === otherDate.getMonth() &&
    someDate.getFullYear() === otherDate.getFullYear();
const isBetween = (date: Date, firstDate: Date | null, lastDate: Date | null) =>
    firstDate && lastDate &&
    ((firstDate.getTime() < date.getTime() &&
        lastDate.getTime() > date.getTime()) ||
        (firstDate.getTime() > date.getTime() &&
            lastDate.getTime() < date.getTime()));
interface CalendarDaysProps {
    month: Date;
    day: Date | null;
    setDay: Function;
    range: boolean;
    finalDay: Date | null;
    setFinalDay: Function;
    hover: Date | null;
    setHover: Function;
}

const CalendarDays: FC<CalendarDaysProps> = ({
    month,
    day,
    setDay,
    range,
    finalDay,
    setFinalDay,
    hover,
    setHover,
}) => {
    const [days, setDays] = useState<any[]>([]);
    useEffect(() => {
        const testDate = new Date(month.getFullYear(), month.getMonth(), 1);
        const firstDay = testDate.getDay();
        const newDays = [];
        let finish = false;
        for (let i = 0; i < 42 && !finish; i += 1) {
            if (i < firstDay) {
                newDays.push({ number: 0 });
            }
            else if (testDate.getMonth() !== month.getMonth()) {
                finish = true;
            }
            else {
                newDays.push({
                    number: testDate.getDate(),
                    date: new Date(testDate),
                    current: isEqual(testDate, new Date()),
                    selected: isEqual(testDate, day) || isEqual(testDate, finalDay),
                    hover: isBetween(testDate, day, finalDay || hover)
                });
                testDate.setDate(testDate.getDate() + 1);
            }
        }
        setDays(newDays);
    }, [month, day, finalDay,hover]);

    const processClick = (date: Date) => {
        if (range && day) {
            if (finalDay) {
                setDay(date);
                setFinalDay(null);
            }
            else if (day.getTime() > date.getTime()) {
                setFinalDay(day);
                setDay(date);
            }
            else {
                setFinalDay(date);
            }
        }
        else {
            setDay(date);
        }
    };
    return (
        <>
            {/* {hover?.toDateString} */}
            {days.map(((d, i) => d.number > 0 ? (
                <div
                    onClick={() => processClick(d.date)}
                    onKeyPress={() => processClick(d.date)}
                    className={`dso_day dim_xxl flex_center ${d.current ? "current" : ""} ${d.hover ? "hover" : ""} ${d.selected ? "selected" : ""}`}
                    key={`notempty${month.getMonth()}${i}`}
                    role="button"
                    tabIndex={0}
                    onMouseEnter={() => setHover(d.date)}
                    onMouseLeave={() => setHover(null)}
                >
                    <Typography scale="large" weight="600">{d.number}</Typography>
                </div>
            ) : (
                <div className="dim_xxl" key={`empty${month.getMonth()}${i}`} />
            )))}
        </>
    );
};



export default CalendarDays;
