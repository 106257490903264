import {
  DtoCreateOzocio,
  DtoResponseOzocio,
  DtoOzocioVehicle,
  DtoResponseOzocioVehicleSucces,
} from "models/ozocio.interface";
import { PandaboardApiInstance } from "./api";
import { Utm, getUtmObject } from "./queryParams";

export const fetchCreateOzocio = async (
  ozocio: DtoCreateOzocio
): Promise<DtoResponseOzocio> =>
  PandaboardApiInstance.post("ozocio", ozocio)
    .then(({ data }) => data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchCreateOzocioVehicle = async (
  vehicle: DtoOzocioVehicle,
  oid: string
): Promise<DtoResponseOzocioVehicleSucces> => {
  const utm: Utm | undefined = getUtmObject();
  return PandaboardApiInstance.post(`ozocio/${oid}/vehicle`, {
    ...vehicle,
    utm
  })
    .then(({ data }) => data)
    .catch((err: any) => {
      throw err.response.data;
    });
};
