import React, { FC } from "react";
import "./styles.scss";
import useStepper from "../Hooks/useStepper";
import StepCountItem from "../StepperCountItem/StepperCountItem";
import { ReactComponent as Up } from "../../../static/icons/checkmark.svg";

interface Props {}

const StepperCountList: FC<Props> = () => {
  const { steps, currentStep } = useStepper();
  return (
    <div className="stepper-count-list">
      {steps.map((step, index) => (
        <div key={`step-count-list-container-${index}`} className="container">
          {index === steps.length - 1 ? (
            <div
              className={`checkmark-container ${
                currentStep === steps.length - 1
                  ? "border_primary_300"
                  : "border_neutral_700"
              }`}
            >
              <Up
                className={currentStep === steps.length - 1 ? "completed" : ""}
              />
            </div>
          ) : (
            <>
              <StepCountItem index={index} step={step} />
              <span className="m_x_xs">---</span>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepperCountList;
