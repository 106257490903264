import React, { FC } from "react";
import "./styles.scss";
import { Ally } from "components/allies/Ally";
import AlliesItem from "components/allies/alliesItem/AlliesItem";
import { Typography } from "design_system/src";

interface Props {
  allies: Ally[];
}

const AlliesList: FC<Props> = ({ allies }) => (
  <div className="display_flex flex_gap_xs">
    <div className="display_flex flex_center">
      <Typography
        scale="heading4"
        weight="600"
        style={{ whiteSpace: "nowrap" }}
        className="display_none_mobile text_center"
        component="h2"
      >
        Nuestros aliados
      </Typography>
    </div>

    <div className="allies-list-container p_y_xl p_x_md">
      {allies.map((ally, i) => (
        <AlliesItem key={`${ally.name}-${i}`} ally={ally} />
      ))}
    </div>
  </div>
);

export default AlliesList;
