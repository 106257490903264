/* eslint-disable prefer-destructuring */
import { useFormikContext } from "formik";
import ReactGA from "react-ga4";
import {
  fetchDeleteFormCrediticioAddDocument,
  fetchFormCrediticioAddDocument,
  fetchOzonerAddDocument,
  fetchOzonerDeleteDocument,
} from "helpers/fetchFormCrediticio";
import { RootState, store } from "store";
import { useSelector } from "react-redux";
import { local } from "store/actions/user";
import {
  toasterNotification,
  ToasterOptions,
} from "helpers/toast/toastNotification";
import { OnUploadInputChangeProps } from "../uploadInput/UploadInput";

const docsOzoner = ["ine_front", "ine_back", "selfie", "proof_of_address"];

export interface IDocumentsOzoner {
  ext: string;
  hash: string;
  mimeType: string;
  name: string;
  path: string;
  size: number;
  typeEvidence: string;
  url: string;
  _id: string;
}

export const useUploadFiles = () => {
  const { user } = useSelector((state: RootState) => state.userReducer);
  const { setFieldValue, setFieldTouched, errors, validateForm } =
    useFormikContext<any>();
  const handleFetchUplodadFile = async ({
    name,
    imageFile,
    imageData,
    location = "OzonFly",
  }: OnUploadInputChangeProps) => {
    const documentName = name.split(".").pop() || "";
    const inOzoner = docsOzoner.includes(documentName);
    if (imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile as Blob);
      formData.append("name", documentName);
      try {
        if (inOzoner) {
          const data = await fetchOzonerAddDocument({
            documentName,
            ozonerId: user._id,
            formData,
          });
          store.dispatch(local(data));
        } else {
          const data = await fetchFormCrediticioAddDocument({
            documentName,
            formData,
          });
          store.dispatch(local(data.user));
        }

        setFieldValue(
          name,
          imageFile && imageData ? { imageFile, imageData } : null
        );
        toasterNotification({
          msg: "¡Documento subido!",
          toasterType: ToasterOptions.success,
          style: "light",
        });
        ReactGA.event(`DOCUMENT_${location}_${documentName}_uploaded`, {
          category: `DOCUMENT_${location}_${documentName}_uploaded`,
          label: `DOCUMENT ${location}, ${documentName} uploaded`,
        });
      } catch (error) {
        toasterNotification({
          msg: "¡Error al subir!",
          toasterType: ToasterOptions.error,
          style: "light",
        });
      }
    } else {
      try {
        if (inOzoner) {
          const documents: IDocumentsOzoner[] = user.documents;
          const docsForDelete = documents.filter(
            (doc) => doc.name === documentName
          );
          if (docsForDelete.length > 0) {
            docsForDelete.forEach(async (docToDel) => {
              const dataupdated = await fetchOzonerDeleteDocument({
                ozonerId: user._id,
                documentId: docToDel._id,
              });
              store.dispatch(local(dataupdated));
              setFieldValue(
                docToDel.name,
                null
              );
              toasterNotification({
                msg: "¡Documento eliminado!",
                toasterType: ToasterOptions.error,
                style: "light",
              });
            });
          }
        } else {
          const { financialForm } = user;
          const Index = financialForm.length - 1;
          const documents: IDocumentsOzoner[] = financialForm[Index].documents;
          if (documents !== undefined && documents.length > 0) {
            const docsForDelete = documents.filter(
              (doc) => doc.name === documentName
            );
            if (docsForDelete.length > 0) {
              docsForDelete.forEach(async (docToDel) => {
                const dataupdated = await fetchDeleteFormCrediticioAddDocument({
                  documentId: docToDel._id,
                });
                const newFinancialForm = dataupdated.user.financialForm;
                const remainingDocuments = newFinancialForm[Index].documents.filter(
                  (doc: { name: string; }) => doc.name !== documentName
                );
                newFinancialForm[Index].documents = remainingDocuments;
                dataupdated.user.financialForm = newFinancialForm;

                store.dispatch(local(dataupdated.user));
                setFieldValue(
                  docToDel.name,
                  null
                );
                toasterNotification({
                  msg: "¡Documento eliminado!",
                  toasterType: ToasterOptions.error,
                  style: "light",
                });
              });
            }
          }
        }
        ReactGA.event(`DOCUMENT_${location}_${documentName}_deleted`, {
          category: `DOCUMENT_${location}_${documentName}_deleted`,
          label: `DOCUMENT ${location}, ${documentName} deleted`,
        });
      } catch (error) {
        toasterNotification({
          msg: "¡Error al eliminar!",
          toasterType: ToasterOptions.error,
          style: "light",
        });
      }
    }
  };
  const handleGenericUploadFile = ({
    name,
    imageFile,
    imageData,
    location,
  }: OnUploadInputChangeProps) => {
    setFieldTouched(name);
    validateForm().then(() => {
      if (!errors[name]) {
        handleFetchUplodadFile({ name, imageFile, imageData, location });
      }
    });
  };
  return handleGenericUploadFile;
};
