import React, { FC } from "react";
import "./styles.scss";
import { Typography } from "design_system/src";
import MediaItem from "../mediaItem/MediaItem";
import { Medium } from "../Medium";

interface Props {
  media: Medium[];
}

const MediaList: FC<Props> = ({ media }) => (
  <div className="display_flex flex_gap_xs">
    <div className="display_flex flex_center">
      <Typography
        scale="heading4"
        weight="600"
        style={{ whiteSpace: "nowrap" }}
        className="display_none_mobile text_center"
        component="h2"
      >
        Medios que escriben sobre Ozon
      </Typography>
    </div>
    <div className="media-list-container p_xl">
      {media.map((medium, idx) => (
        <MediaItem key={`cImg-${idx}`} medium={medium} />
      ))}
    </div>
  </div>
);

export default MediaList;
