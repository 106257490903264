import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Typography } from "design_system/src";

export const RejectedView = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state) {
            ReactGA.event("VIEW_Financial_rejected", {
                category: "VIEW_Financial_rejected",
                label: "page view for the rejected information view",
                email: state.email,
                curp: state.curp,
            });
        } else {
            navigate("/");
        }
    }, []);

    return (
        <div className="dso_container" style={{ minHeight: "50vh" }}>
            {state && <div className="display_center flex_col flex_center p_y_xxxl m_y_xxxl m_x_auto w_60_per_desktop">
                <Typography
                    scale="heading1"
                    weight="600"
                    className="m_b_xxxl_desktop m_b_xl_mobile text_center"
                >¡Hola!</Typography>
                <Typography
                    scale="heading4"
                    scaleMobile="large"
                    weight="600"
                    className="m_b_xl text_center"
                >Queremos agradecerte sinceramente por tomarte el tiempo de solicitar un crédito con Ozon. Valoramos tu confianza en nosotros.</Typography>
                <Typography
                    scale="medium"
                    weight="400"
                    className="m_b_xl text_center"
                >Hemos evaluado cuidadosamente tu solicitud de crédito. Aunque no podemos avanzar con la aprobación en este momento, Te invitamos a intentarlo mas adelante.</Typography>
                <Typography
                    scale="medium"
                    weight="400"
                    className="m_b_xl text_center"
                >Cordialmente, El equipo de Ozon.</Typography>
                <button
                    type="button"
                    className="bg_transparent border_none text_link"
                    onClick={() => navigate("/")}
                >
                    <Typography
                        scale="medium"
                        scaleMobile="large"
                        weight="400"
                    >Regresar al Inicio</Typography>
                </button>
            </div>}
            {!state && <div className="display_center flex_col flex_center p_y_xxxl m_y_xxxl m_x_auto w_60_per_desktop">
                <Typography scale="medium" weight="400" className="text_center">Cargando...</Typography>
            </div>}
        </div>
    );
};
