/* eslint-disable no-unneeded-ternary */
import React, { FC, useState, useEffect } from "react";
import moment from "moment";
import { ReactComponent as Right } from "../../../static/icons/chevron-right.svg";
import { ReactComponent as Left } from "../../../static/icons/chevron-left.svg";
import { Typography } from "../../Typography";


interface ShortDateInputProps extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> {
    value?: any
    onChange?: any
    className?: string;
}
const ShortDateInput: FC<ShortDateInputProps> = ({ value = "", onChange, name = "", className }) => {
    const [date, setDate] = useState<Date>(new Date());
    useEffect(() => {
        const newDate = value ? new Date(value) : new Date();
        newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
        setDate(newDate);
    }, [value]);
    const changeDate = (ammount: number) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + ammount);
        if (onChange)
            onChange({
                target: {
                    name,
                    value: moment(newDate).format("YYYY-MM-DD")
                }
            });
    };
    return (
        <div className={`display_flex flex_justify_between dso_short_date_cont flex_align_center ${className}`}>
            <Left className="" tabIndex={0} role="button" onClick={() => changeDate(-1)} />
            <Typography scale="large" weight="400" className="text_no_break">
                {moment(date).locale("es").format("dddd, D MMMM")}
            </Typography>
            <Right className="" tabIndex={0} role="button" onClick={() => changeDate(1)} />
        </div>
    );
};

export default ShortDateInput;
