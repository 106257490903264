/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useRef, useState } from "react";

import {
  Container,
  Grid,
  Pagination,
  PaginationItem,
  PaginationRenderItemParams,
  useMediaQuery,
} from "@mui/material";
import {
  Button,
  Typography,
} from "design_system/src";
import { ReactComponent as Filter } from "design_system/src/static/icons/filter-descending.svg";


import { styled } from "@mui/material/styles";

import { useDispatch } from "react-redux";
import _ from "lodash";
import { SelectedFilters } from "store/reducers/vehiclesReducer";
import { useNavigate } from "react-router-dom";
import CatalogBanner from "../catalogBanner/CatalogBanner";
import SkeletonCard from "../skeletonCard/SkeletonCard";
import OzonCardVehiclesPagination from "../ozonCardVehiclesPagination/OzoneCardVehiclesPagination";
import useCurrentCity from "../../hooks/useCurrentCity";
import useVehicles from "../../hooks/useVehicles";
import {
  fetchVehicles,
  setSelectedFilters,
  updateSelectedFilters,
} from "../../store/actions/vehicles";
import NewSideBar from "./NewSideBar";

import InputSearchTextFilter from "./InputSearchTextFilter";
import FiltersModal from "./FiltersModal";
import useScrollDirection from "../../hooks/useScrollDirection";

import "./NewCatalog.css";
import { VehicleConditionFilter } from "./VehicleConditionFilter/VehicleConditionFilter";
import { FilterTagList } from "./filterTagList/FilterTagList";
import { SortVehicles } from "./sortVehicles/SortVehicles";
import { useCatalogFilters } from "./catalogFilters/useCatalogFilters";

type ItemType = "list" | "card";

const scrollTop = () => {
  window.focus();
  window.scrollTo(0, 0);
};

const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
  borderRadius: "10px",
  minWidth: 36,
  fontSize: "14px",
  "&:hover": {
    borderRadius: "10px",
    backgroundColor: "#FDE9D3",
    color: "#FE8A02",
  },
  "&.Mui-selected": {
    borderRadius: "10px",
  },
}));

const CustomPaginationItem = (props: PaginationRenderItemParams) => {
  return <StyledPaginationItem {...props} />;
};

const NewCatalog = () => {
  const [isFixed, setIsFixed] = useState(true);
  const divRef = useRef<HTMLDivElement>(null);
  const bannerRef = useRef<HTMLDivElement>(null);
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const matchesXS = useMediaQuery("(min-width:600px)");
  const matchesSM = useMediaQuery("(min-width:900px)");
  const matchesMD = useMediaQuery("(min-width:1200px)", { noSsr: true });

  const checkIfShouldBeResized = () => {
    const filterContainer = filterContainerRef.current?.getBoundingClientRect();
    divRef.current!.style.width = `${filterContainer?.width}px`;

    if (matchesSM) {
      divRef.current!.style.paddingRight = "30px";
    } else {
      divRef.current!.style.paddingRight = "";
    }
  };

  const checkIfShouldBeFixed = () => {
    if (matchesSM) {
      const rect = bannerRef.current?.getBoundingClientRect();
      setIsFixed(rect ? rect.y > window.innerHeight : false);
    } else {
      setIsFixed(false);
    }
  };

  const handleScroll = () => {
    checkIfShouldBeFixed();
  };

  const handleResize = () => {
    checkIfShouldBeFixed();
    checkIfShouldBeResized();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    checkIfShouldBeFixed();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [matchesSM]);

  useEffect(() => {
    checkIfShouldBeResized();
  }, [filterContainerRef.current?.getBoundingClientRect().width]);

  const itemsPerPage = matchesMD ? 32: 30;
  const helperCardPos: number = 8;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const city = useCurrentCity();

  const {
    loading: vehiclesLoading,
    selectedFilters,
    data: { vehicles, page, pages, count },
  } = useVehicles();

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    document.title = "OZON | Catálogo";
    scrollTop();
  }, []);

  const [updateCatalogFilters] = useCatalogFilters();

  useEffect(() => {
    updateCatalogFilters(
      {...selectedFilters, city, "limit": itemsPerPage, "page": 1},
      false
    );
  }, [city]);


  useEffect(() => {
    if (!vehiclesLoading) {
      setCurrentPage(page);
      setTotalPages(pages);
    }
  }, [page, pages]);

  useEffect(() => {
    if (!vehiclesLoading) {
      scrollTop();
      setIsFixed(false);
    }
  }, [vehiclesLoading]);

  const listStartRef = useRef<null | HTMLDivElement>(null);

  const handleChange = (event: any, value: any) => {
    updateCatalogFilters({"page": value});
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const scrollDirection = useScrollDirection();
  const [selectedTotalFilters, setSelectedTotalFilters] = useState<number>(0);

  useEffect(() => {
    if (selectedFilters) {
      setSelectedTotalFilters(Object.keys(selectedFilters).filter((key) => key !== "limit" && key !== "city" && key !== "page").length);
    }
  }, [selectedFilters]);

  return (
    <div
      ref={listStartRef}
      className="p_t_xl_mobile bg_neutral_300"
    >
      <section className={`${ matchesSM ? " display_none" : "display_flex flex_col"}`}>
        <div
          className={`mobile_header_sitcky  animate__animated ${
            scrollDirection === "down"
              ? "animate__slideOutUp"
              : "animate__slideInDown"
          }`}
        >
          <div className="p_x_lg">
            <InputSearchTextFilter withButton updateCatalogFilters={updateCatalogFilters}/>
          </div>
          <div
            className="display_flex p_x_lg"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Button
              scale="small"
              className="m_r_md"
              onClick={() => setModalOpen(true)}
              icon={<Filter />}
            >
              Filtros ({selectedTotalFilters})
            </Button>
            <SortVehicles updateCatalogFilters={updateCatalogFilters}/>
          </div>
        </div>
      </section>

      <Container maxWidth="xl" style={{ padding: 0 }}>
        <Grid container spacing={0} style={{ padding: "0 3vw" }}>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            style={{ display: !matchesSM ? "none" : "" }}
            ref={filterContainerRef}
          >
            <div
              ref={divRef}
              style={{
                position: isFixed ? "fixed" : "static",
                display: isFixed ? "block" : "",
              }}
            >
              <NewSideBar open setCurrentPage={setCurrentPage} updateCatalogFilters={updateCatalogFilters}/>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={9}
            style={{
              marginTop: !matchesSM ? "105px" : "0",
            }}
          >
            <VehicleConditionFilter updateCatalogFilters={updateCatalogFilters}/>

            <div style={{
              display: !matchesSM ? "none" : "",
              paddingLeft: 12,
              paddingRight: 12,
              marginTop: 25,
              marginBottom: 25,
            }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  gap: 8,
                  marginBottom: 5,
                }}
              >
                <div className="display_none_mobile">
                  {vehicles && vehicles.length > 0 && !vehiclesLoading && (
                    <div className="display_flex  flex_align_center">
                      <Typography
                        scale="medium"
                        className="display_flex"
                        weight="400"
                      >
                        <b className="m_r_xs">{count} </b> moto
                        {count !== 1 ? "s" : ""}
                      </Typography>
                    </div>
                  )}
                </div>
                <SortVehicles updateCatalogFilters={updateCatalogFilters}/>
              </div>

              <FilterTagList updateCatalogFilters={updateCatalogFilters}/>
            </div>

            {vehiclesLoading ? (
              <div className="m_t_md m_b_lg">
                <SkeletonCard
                  showItems={!matchesXS ? 4 : 8}
                  columnsPerCard={!matchesXS ? 12 : !matchesMD ? 4 : 3}
                />
              </div>
            ) : (
              <OzonCardVehiclesPagination
                count={count}
                vehicles={vehicles}
                helperCardPos={helperCardPos}
                updateCatalogFilters={updateCatalogFilters}
              />
            )}

            {totalPages > 1 && <div
              className="m_b_xl"
              style={{ display: "flex", padding: 20, justifyContent: "center" }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChange}
                hideNextButton
                hidePrevButton
                renderItem={(props) => <CustomPaginationItem {...props} />}
              />
            </div>}
            <br />
          </Grid>

        </Grid>
      </Container>

      <div ref={bannerRef}>
        <CatalogBanner />
      </div>
      <FiltersModal
        count={count}
        open={modalOpen}
        setOpen={setModalOpen}
        filterVehicles={vehicles}
        setCurrentPage={setCurrentPage}
        updateCatalogFilters={updateCatalogFilters}
      />
    </div>
  );
};

export default NewCatalog;
