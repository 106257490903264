/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import _ from "lodash";
import "./counter.scss";
import { ReactComponent as Mas } from "../../../static/icons/mas.svg";
import { ReactComponent as Menos } from "../../../static/icons/menos.svg";



interface CounterInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name?: string;
  min?: number;
  max?: number;
  className?: string;
  value:number;
}

const Counter: React.FC<CounterInputProps> = ({
  name,
  min,
  max,
  className,
  value=0,
  onChange,
  ...props
}) => {
  const [val,setVal]= useState<number>(value);
  useEffect(()=>{

    if (typeof (val) === "number"){
      const response:any={target:{name,value:val}};
      const number = val;
      if (typeof (min) === "number" && number < min) {
        response.target.value=min;
        setVal(min);
      }
      if (typeof (max) === "number" && number > max) {
        response.target.value=max;
        setVal(max);
      }
      if(onChange)
      (onChange as Function)(response);
    }
  },[min,max,val]);
  return (
  <div className="dso_counter">
    <input
      type="number"
      className={`dso_input text_center ${className}`}
      value={value as number}
      name={name}
      onChange={(e) =>{setVal(parseInt(e.target.value,10));}}
      {...(props as React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >)}
    />
    <Menos
        className={`dim_lg dso_indicator_left ${(typeof (min) !== "number") || value > min ? "text_primary_300 cursor_pointer" : "text_neutral_500"
        }`}
        onClick={() => setVal((typeof (value) === "number"?value:0) - 1)}
    />
    <Mas
        className={`dim_lg dso_indicator_right ${(typeof (max) !== "number") || value < max ? "text_primary_300 cursor_pointer" : "text_neutral_500"
        }`}
        onClick={() => setVal((typeof (value) === "number" ? value : 0) + 1)}
    />
  </div>
  );
};

export default Counter;