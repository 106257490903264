import React, { FC, useMemo } from "react";
import _ from "lodash";
import {
  CarouselItem,
  CarouselProvider,
  Typography,
  useWindowSize,
  useStepper,
} from "design_system/src";
import { ReactComponent as RightArrow } from "design_system/src/static/icons/right-lg.svg";
import { ReactComponent as LeftArrow } from "design_system/src/static/icons/left-lg.svg";
import OzonCardVehicle from "components/cards/OzonCardVehicle";
import PreCatalogCarousel from "./PreCatalogCarousel";
import useVehicles from "../../../../hooks/useVehicles";
import "./styles.scss";
import { BrandSection } from "../brandSection/BrandSection";

interface Props {}

const PAGES = 4;

const PreCatalogControl = () => {
  const {
    incrementCurrentStep,
    decrementCurrentStep,
    setCurrentStep,
    currentStep,
    steps,
  } = useStepper();
  return (
    <div className="display_flex flex_align_center h_100_per">
      <LeftArrow
        className={`${
          currentStep === 0 ? "text_neutral_700" : "text_primary_300"
        }`}
        onClick={decrementCurrentStep}
      />
      <div className="display_flex flex_1 flex_justify_center">
        {_.range(PAGES).map((page, index) => (
          <div
            key={index}
            className={`slider-point cursor_pointer ${
              currentStep === index ? "active" : ""
            }`}
            onClick={() => setCurrentStep(index)}
          />
        ))}
      </div>
      <RightArrow
        className={`${
          currentStep === steps.length - 1
            ? "text_neutral_700"
            : "text_primary_300"
        }`}
        onClick={incrementCurrentStep}
      />
    </div>
  );
};

const PreCatalogSection: FC<Props> = () => {
  const {
    data: { homeVehicles: vehicles },
    error,
    loading,
  } = useVehicles();
  const { width: windowWidth } = useWindowSize();

  // eslint-disable-next-line no-nested-ternary
  const itemsPerPage = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => (windowWidth > 1200 ? 4 : windowWidth > 800 ? 3 : 1),
    [windowWidth]
  );
  const selectedVehicles = useMemo(
    () =>
      _.chunk(_.sampleSize(vehicles, itemsPerPage * PAGES), itemsPerPage).map(
        (chunk, index) => (
          <CarouselItem value={`${index}`} name={`${index}`} key={`${index}`}>
            <div
              className="carousel-item-container"
              style={{
                gridTemplateColumns: `repeat(${itemsPerPage}, minmax(200px, 1fr))`,
              }}
            >
              {chunk.map((vehicle) => (
                <div className="m_r_lg_desktop p_y_xl" key={vehicle._id}>
                  <OzonCardVehicle isHelperCard={false} vehicle={vehicle} />
                </div>
              ))}
            </div>
          </CarouselItem>
        )
      ),
    [vehicles, itemsPerPage]
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!loading && !error && selectedVehicles.length > 0 && (
        <section className="bg_neutral_200">
          <div className="dso_container_desktop p_t_xl">
            <Typography
              className="text_center display_none_mobile m_none"
              weight="600"
              textColor="neutral_1000"
              scale="heading4"
              component="h2"
            >
              Motos Seminuevas, en excelente estado y con los mejores precios.
            </Typography>
            <Typography
              className="text_center display_none_desktop m_x_xl m_y_none"
              weight="600"
              textColor="neutral_1000"
              scale="heading4"
              component="h2"
            >
              Motos Seminuevas, en excelente estado.
            </Typography>
            <div className="pre-catalog-section-container">
              <CarouselProvider>
                <div
                  style={{ gridArea: "labels" }}
                  className="display_none_mobile m_t_xl"
                >
                  <Typography
                    className="text_center_mobile m_none"
                    weight="600"
                    scale="heading2"
                    component="h3"
                  >
                    Encuentra tu moto ideal
                  </Typography>
                  <Typography
                    className="m_y_lg text_center_mobile m_x_none"
                    weight="400"
                    textColor="neutral_700"
                    scale="large"
                    component="p"
                  >
                    ¡No pierdas la oportunidad de disfrutar estos{" "}
                    <b>descuentos que tenemos para ti!</b>
                  </Typography>
                </div>
                <div
                  className="text_center_mobile dim_100_per slider-control m_b_lg_mobile"
                  style={{ gridArea: "control", alignSelf: "center" }}
                >
                  <PreCatalogControl />
                </div>
                <div style={{ gridArea: "carousel" }} className="m_y_lg_mobile">
                  <PreCatalogCarousel renderedVehicles={selectedVehicles} />
                </div>
                <div style={{ gridArea: "brands" }}>
                  <BrandSection />
                </div>
              </CarouselProvider>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PreCatalogSection;
