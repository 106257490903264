import React, { FC } from "react";
import  Typography  from "../Typography/Typography";
import { ReactComponent as Left } from "../../static/icons/chevron-left.svg";
import { ReactComponent as Right } from "../../static/icons/chevron-right.svg";
import "./calendar.scss";
import CalendarDays from "./CalendarDays";

const diasSemana = [
    "Dom",
    "Lun",
    "Mar",
    "Mie",
    "Jue",
    "Vie",
    "Sab",
];
const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
];

interface CalendarProps {
    day:Date|null;
    setDay:Function;
    month:Date;
    setMonth:Function;
    range?: boolean;
    finalDay?:Date|null;
    setFinalDay?: Function;
    hover?: Date | null;
    setHover?: Function;
    className?: string;
}

const Calendar: FC<CalendarProps> = ({
    day,
    setDay,
    month,
    setMonth,
    range=false,
    finalDay=null,
    setFinalDay=()=>{},
    hover = null,
    setHover = () => { },
    className = "",
}) => {
    const changeMonth=((up:boolean)=>{
        const d = new Date(month);
        d.setMonth(d.getMonth()+(up?1:-1));
        setMonth(d);
    });
    return (
        <div
            className={`dso_calendar ${className} bg_neutral_0 p_md dim_fit`}
        >
            <div className="dso_calendar_header flex_center">
                <Left onClick={()=>changeMonth(false)}/>
                <Typography className="p_x_xxs" scale="large" weight="600" textColor="neutral_900">
                    {meses[month.getMonth()]} {month.getFullYear()}
                </Typography>
                <Right onClick={() => changeMonth(true)} />
            </div>
            <div className="dso_calendar_body">
                <div className="dso_weekdays p_y_xs">
                    {diasSemana.map(dia => (
                        <Typography key={dia} scale="small" weight="600" textColor="neutral_900" className="w_xxl text_center">{dia}</Typography>
                    ))}
                </div>
                <div className="dso_days">
                    <CalendarDays month={month} day={day} setDay={setDay} range={range} finalDay={finalDay} setFinalDay={setFinalDay}
                        hover={hover}
                        setHover={setHover} />
                </div>
            </div>
        </div>
    );
};

export default Calendar;
