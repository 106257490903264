import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Button } from "design_system/src";
import { ReactComponent as Motorcycle } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as Dollar } from "design_system/src/static/icons/money.svg";
import Hero from "./Hero";

const HeroSection: FC = () => {
  const [open, setopen] = useState<boolean>(false);
  const navigate = useNavigate();
  const showModal = () => {
    setopen(!open);
  };

  return (
    <section className="display_flex flex_align_center flex_col_mobile dso_container">
      <div className="display_flex flex_col flex_justify_center flex_1 flex_order_2_mobile text_center_mobile m_t_xs_mobile">
        <div className="dso_card_small p_xxl m_r_xxl_desktop">
          <Typography scale="heading1" scaleMobile="heading2" weight="600" className="m_none" component="h2">
            <span>
              Comienza tu aventura,
              <span className="text_primary_300"> maneja con estilo.</span>
            </span>
          </Typography>
          <Typography scale="large" scaleMobile="small" weight="600" className="m_t_md m_none" component="p">
            Únete a la familia Ozon y disfruta de todos los
            beneficios que tenemos para ti!
          </Typography>
          <div className="display_flex m_t_xl flex_col_mobile">
            <Link to="/catalogo" className="custom_a">
              <Button
                className="m_r_md w_100_per w_100_per_mobile m_b_lg_mobile"
                variant="principal"
                scale="small"
                icon={<Motorcycle />}
              >
                Encuentra tu moto Ideal
              </Button>
            </Link>

            {/* <Button
              onClick={() => navigate("/formulario-vende-tu-moto")}
              className="w_50_per w_100_per_mobile bg_neutral_900"
              variant="principal"
              scale="small"
              icon={<Dollar />}
            >
              Vende tu moto
            </Button> */}
          </div>
        </div>

      </div>
      <div className="flex_1 w_100_per" style={{ overflow: "hidden" }}>
        <Hero />
      </div>
    </section>
  );
};

export default HeroSection;
