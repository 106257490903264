/* eslint-disable no-unused-vars */
import React, { FC, useEffect, useRef } from "react";

// styles
import "./styles.scss";
import useStepper from "../Hooks/useStepper";
import useDevice from "../../../hooks/useDevice";

interface Props {
  startStep?: number;
  onChange?: (step: number) => void;
  cancelScrollOnChange?: boolean;
}

const Stepper: FC<Props> = ({ children, startStep, onChange, cancelScrollOnChange }) => {
  const { setCurrentStep, currentStep } = useStepper();
  const initialized = useRef(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useDevice();
  const prevStep = useRef(currentStep);

  useEffect(() => {
    initialized.current = true;
  }, []);
  
  useEffect(() => {
    if (onChange && initialized.current) {
      onChange(currentStep);
    }
  }, [currentStep, onChange]);

  useEffect(() => {
    if (elementRef && elementRef.current && initialized.current && isMobile && (currentStep > 0 || prevStep.current > 0) && !cancelScrollOnChange) {
      window.scrollTo({ top: elementRef.current.getBoundingClientRect().top + window.scrollY, behavior: "smooth" });
    }
    prevStep.current = currentStep;
  }, [currentStep]);

  useEffect(() => {
    if (startStep) {
      setCurrentStep(startStep);
    }
  }, [setCurrentStep, startStep]);

  return <div ref={elementRef} className="stepper-container">{children}</div>;
};

export default Stepper;
