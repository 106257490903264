import React, { FC } from "react";
import "./styles.scss";
import AlliesList from "components/allies/alliesList/AlliesList";
import { ReactComponent as MUNDIMOTOS } from "static/icons/mundimotos.svg";
import { ReactComponent as MOTUL } from "static/icons/motul.svg";

import Ivoy from "static/icons/ivoy.png";
import { Typography } from "design_system/src";

import Coppel from "static/icons/coppel_logo.png";
import DiDi from "static/icons/didi_food.png";
import Uber from "static/icons/uber_logo.png";

interface Props {}

const AlliesSection: FC<Props> = () => (
  <section className="allies-section-container m_t_xxxl dso_container">
    <div className="title-container display_none_desktop">
      <Typography
        scale="heading3"
        weight="600"
        className="m_none"
        component="h2"
      >
        Nuestros aliados 🤝
      </Typography>
    </div>
    <AlliesList
      allies={[
        {
          name: "Uber",
          link: "https://uber.ozon.mobi/",
          image: <img src={Uber} alt="Uber" className="w_100_px" />,
          logoOnly: true,
        },
        {
          name: "DiDi Food",
          link: "https://didi.ozon.mobi/",
          image: <img src={DiDi} alt="DiDi" className="w_100_px" />,
          logoOnly: true,
        },
        {
          name: "Ivoy",
          link: "https://ivoy.mx/",
          image: <img src={Ivoy} alt="Ivoy" className="w_100_px" />,
          logoOnly: true,
        },
        {
          name: "Coppel",
          link: "https://www.coppel.com/",
          image: <img src={Coppel} alt="Coppel" className="w_100_px" />,
          logoOnly: true,
        },
        {
          name: "Motul",
          link: "https://www.motul.com/mx/",
          image: <MOTUL className="w_100_px" />,
          logoOnly: true,
        },
        {
          name: "Mundimotos",
          link: "https://www.mundimotos.com.mx/",
          image: <MUNDIMOTOS className="w_100_px" />,
          logoOnly: true,
        },
      ]}
    />
  </section>
);

export default AlliesSection;
