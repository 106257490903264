/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-prototype-builtins */

import React, {
  useState,
  useEffect,
  ReactElement,
  Key,
  FC,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import useValidateStep from "../Hooks/useValidateStep";
import StepIndicator from "../StepIndicator/StepIndicator";
import { Typography, Button, ModalConfirmation } from "../../../components";

interface IStepper {
  component: ReactElement<any, any>;
  validationKey?: string;
  stepTitle?: string;
  stepModalConfig?: any;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 1,
    backgroundColor: theme.palette.mode === "light" ? "#FE8A02" : "#FE8A02",
  },
}));

type NewStepperProps = {
  hasProgressBar?: boolean;
  stepperComponents: Array<IStepper>[];
  validateStep: (step: any, values: any) => any;
  onSubmitForm?: any;
  loading?: boolean;
  message?: React.ReactNode;
};

const NewStepper: FC<NewStepperProps> = ({
  hasProgressBar = true,
  stepperComponents,
  validateStep,
  onSubmitForm,
  loading,
  message,
}) => {
  const useStyles = makeStyles(() => ({
    header: {
      backgroundColor: "rgb(255,255,255)",
      borderRadiusTop: 50,
    },
    footer: {
      backgroundColor: "rgb(255,255,255)",
      padding: "1rem",
    },
  }));
  const classes = useStyles();
  const navigate = useNavigate();
  const intialRemainingState = stepperComponents
    .map((_: any, idx: number) => idx + 1)
    .slice(1);

  const [activeStep, setActiveStep] = useState(0);
  const [remainingSteps, setRemainingSteps] = useState<number[] | []>(
    intialRemainingState
  );
  const [currentSteps, setCurrentSteps] = useState<number[] | []>([1]);
  const [stepperBody, setStepperBody] = useState<any>();
  const [subcomponentCurrentStep, setSubcomponentCurrentStep] =
    useState<number>(0);
  const [stepModalProps, setStepModalProps] = useState<any>(undefined);

  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const handleClose = () => setOpenConfirmation(!openConfirmation);

  const subComponentsStepList = stepperComponents[activeStep];

  const incrementStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const _lastValue = Number(currentSteps.slice(-1)) + 1;
    setCurrentSteps((prevState: any) => [...prevState, _lastValue]);
    remainingSteps.shift();
  };

  const decrementStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    currentSteps.pop();
    setCurrentSteps(currentSteps);
    const _firstValue =
      remainingSteps.length === 0
        ? stepperComponents.length + 1
        : remainingSteps.slice(0, 1).shift();
    setRemainingSteps((prevState: any) => [_firstValue! - 1, ...prevState]);
    setSubcomponentCurrentStep(0);
  };

  const maxSteps = stepperComponents.length;
  const isValid = useValidateStep(
    stepperComponents[activeStep][subcomponentCurrentStep].validationKey,
    validateStep
  );

  useEffect(() => {
    setStepperBody(subComponentsStepList[subcomponentCurrentStep].component);
  }, [activeStep, subcomponentCurrentStep]);

  const handleNext = () => {
    if (subcomponentCurrentStep === subComponentsStepList.length - 1) {
      if (activeStep === maxSteps - 1) {
        if (onSubmitForm) {
          onSubmitForm();
        }
      } else {
        incrementStep();
        setSubcomponentCurrentStep(0);
      }
    } else {
      setSubcomponentCurrentStep((prevState) => prevState + 1);
    }
  };

  const handleBack = () => {
    if (subcomponentCurrentStep === 0 && activeStep === 0) {
      navigate(-1);
      return;
    }
    if (subcomponentCurrentStep === 0) {
      decrementStep();
      return;
    }
    setSubcomponentCurrentStep((prevState) => prevState - 1);
  };

  const renderNextTextButton = (): string => {
    if (loading) {
      return "Enviando...";
    }
    if (
      activeStep === maxSteps - 1 &&
      subcomponentCurrentStep === subComponentsStepList.length - 1
    ) {
      return "Terminar y continuar";
    }
    return "Siguiente";
  };

  const onConfirmationCallBack = async () => {
    handleClose();
    handleNext();
  };

  const handleOnclickBehaivor = () => {
    if (stepModalProps) {
      setOpenConfirmation(true);
      return;
    }

    if (
      activeStep === maxSteps - 1 &&
      subcomponentCurrentStep === subComponentsStepList.length - 1
    ) {
      onSubmitForm();
      return;
    }
    handleNext();
  };

  const setProgressBarValue = (idx: number) =>
    hasProgressBar ? (idx <= activeStep ? 100 : 0) : 0;

  const componentStartRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    componentStartRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [activeStep, subcomponentCurrentStep]);

  useEffect(() => {
    if (
      stepperComponents[activeStep][subcomponentCurrentStep].stepModalConfig
    ) {
      setStepModalProps(
        stepperComponents[activeStep][subcomponentCurrentStep].stepModalConfig
      );
    } else {
      setStepModalProps(undefined);
    }
  }, [stepperComponents, activeStep, subcomponentCurrentStep]);

  return (
    <div>
      <div className="bg_neutral_300">
        <div className="dso_card">
          <Box ref={componentStartRef} className={classes.header}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={8} className="m_b_md p_l_xl">
                <Typography
                  className="text_primary_300"
                  scale="medium"
                  weight="600"
                >
                  <span>
                    {currentSteps.map((step: any, idx: number) => (
                      <StepIndicator
                        key={idx}
                        currentNumber={step}
                        isActive
                        isClickeable
                        steps={stepperComponents}
                        setStepperBody={setStepperBody}
                        setActiveStep={setActiveStep}
                        setCurrentSteps={setCurrentSteps}
                        setRemainingSteps={setRemainingSteps}
                      />
                    ))}
                  </span>{" "}
                  {stepperComponents[activeStep][0].stepTitle}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <span>
                  {remainingSteps.map((step: any, idx: number) => (
                    <StepIndicator key={idx} currentNumber={step} />
                  ))}
                </span>
              </Grid>
              {message && (
                <Grid item xs={12}>
                  {message}
                </Grid>
              )}
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {hasProgressBar && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Grid spacing={0} container padding={0}>
                  {stepperComponents.map((_: any, idx: number) => (
                    <Grid key={idx} xs item>
                      <BorderLinearProgress
                        variant="determinate"
                        value={setProgressBarValue(idx!)}
                        className="shadow_hard"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            <Box
              sx={{
                width: "100%",
                p: "5px 5%",
                marginTop: "10px",
                minHeight: 350,
              }}
            >
              {stepperBody}
            </Box>
            <div className={classes.footer}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                sx={{
                  "@media (max-width: 899px)": {
                    flexDirection: "column-reverse",
                  },
                }}
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <Button
                    className="w_100_per"
                    variant="outline"
                    scale="small"
                    icon={<ArrowBackIcon />}
                    onClick={handleBack}
                  >
                    Volver
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  {loading ? (
                    <Box
                      className="m_t_xs"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        weight="400"
                        scale="medium"
                        textColor="neutral_600"
                        className="m_x_lg"
                      >
                        Enviando...
                      </Typography>
                      <CircularProgress size={22} sx={{ color: "#FE8A02" }} />
                    </Box>
                  ) : (
                    <Button
                      className="w_100_per"
                      orientation="right"
                      scale="small"
                      icon={<ArrowForwardIcon />}
                      disabled={!isValid || loading}
                      onClick={handleOnclickBehaivor}
                    >
                      {renderNextTextButton()}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>
      </div>
      {stepModalProps && (
        <ModalConfirmation
          icon={stepModalProps.icon}
          handleClose={handleClose}
          openConfirmation={openConfirmation}
          onConfirmationCallBack={() => onConfirmationCallBack()}
          isConfirmationLoading={false}
          title={stepModalProps.title}
          subtitle={stepModalProps.subtitle}
          copyReject="No, regrésame"
          copyConfirm="Si, seguro"
        />
      )}
    </div>
  );
};

export default NewStepper;
