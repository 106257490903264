import React, { FC } from "react";
import { Typography } from "design_system/src";
import { Cities } from "store/reducers/cityReducer";
import { HubsList } from "./HubsList";
import img12 from "./img12.png";
import img13 from "./img13.png";
import img13M from "./img13M.png";
import forum from "./forum.png";
import forumGmaps from "./forumGmaps.png";
import forumGmapsMb from "./forumGmapsMobile.png";
// import img14 from "./img14.png";
// import img15 from "./img15.png";

import guadalajaraMap from "./ozon-mapa-Guadalajara.png";
import guadalajaraMapM from "./ozon-mapa-Guadalajara-mobil.png";
import guadalajaraFachada from "./ozon-guadalajara-fachada.png";

import "./hub.scss";

interface Props {}

export const HubsSection: FC<Props> = () => (
  <section
    className="w_100_per bg_neutral_200 p_y_xl hub"
    style={{ border: "1px solid #EAEAEA" }}
  >
    <div className="w_100_per dso_container ">
      <div className="w_100_per display_none_desktop">
        <Typography scale="heading3" weight="600" className="text_neutral_900 m_none" component="h2">
          Prefieres un contacto más directo
        </Typography>

        <Typography scale="small" weight="400" className="text_neutral_900 m_none" component="p">
          Encuéntranos en ciudad de México en los siguientes puntos de atención.
        </Typography>

      </div>
      <HubsList
        hubs={[
          {
            title: "Anáhuac",
            img: img12,
            map: img13,
            mapM: img13M,
            desc: "Laguna de Mayran 396, Anáhuac I Secc., Anáhuac I Secc, Miguel Hidalgo, 11320 Ciudad de México, CDMX, México",
            url: "https://goo.gl/maps/yM8sDtMi7MqVyRe59",
            city: Cities.CDMX,
          },
        ]}
      />
    </div>
  </section>
);
