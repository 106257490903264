export const formatCity = (city: string) => {
  switch (city) {
    case "602479bab88ce81d7ea7a275":
      return "602479bab88ce81d7ea7a275";
    case "62c2f56d235e93fe7cf3bd5c":
      return "62c2f56d235e93fe7cf3bd5c";

    default:
      return "602479bab88ce81d7ea7a275";
  }
};
