/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { store } from "store";
import { logout, setTempToken } from "store/actions/user";

import { fetchGetFinancialForm, fetchGetTempToken } from "helpers/fetchMiCuenta";
import { useFinancialForm, transformFinancialFormObj } from "hooks/useGetFinancialForm";

import { AuthCard } from "views/auth/AuthCard";
import { Typography } from "design_system/src";
import { OzonFlyForm } from "./ozonFlyForm/OzonFlyForm";
import { OzonFlyFormCompleted } from "./ozonFlyForm/OzonFlyFormCompleted";

type param = {
    formId: string;
};

type jsonProps = {
    exp?: number;
}

export const OzonFlyScreen: React.FC = () => {
    const navigate = useNavigate();
    const { formId } = useParams();
    const intervalRef = useRef<any>(null);

    const { tempToken } = store.getState().userReducer;
    const [financialForm, setFinancialForm] = useFinancialForm();

    const [remainingTime, setRemainingTime] = useState<number>(0);
    const [workDigitalPlatforms, setWorkDigitalPlatforms] = useState<boolean>(false);
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(true);

    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            store.dispatch(setTempToken(""));
        }
    };

    const startInterval = (json: jsonProps) => {
        intervalRef.current = setInterval(() => {
            setRemainingTime(json.exp ? (json.exp! * 1000) - new Date().getTime() : 0);
        }, 1000);
    };

    useEffect(() => {
        if (tempToken !== "" && formId) {
            const base64Url = tempToken.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(atob(base64).split("").map((c) => {
                return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
            }).join(""));
            const json = JSON.parse(jsonPayload);

            fetchGetFinancialForm(formId).then((data) => {
                setFinancialForm(transformFinancialFormObj(data.ozoner));
            });
            startInterval(json);
            return () => stopInterval();
        }
        setLoading(false);
        return () => {};
    }, []);

    useEffect(() => {
        if (financialForm) {
            setWorkDigitalPlatforms(financialForm.workInDigitalPlatforms);
            if (financialForm?._id !== formId) {
                setRemainingTime(0);
            }
        }
    }, [financialForm]);

    useEffect(() => {
        if (remainingTime > 0) {
            setLoading(false);
        } else if (remainingTime <= 0 && !loading) {
            stopInterval();
        }
    }, [remainingTime]);

    return !loading ? (
        <div className="dso_container m_y_xxl" style={{ minHeight: "75vh", maxWidth: 1440 }}>
            {remainingTime > 0 && <div>
                <div className="bg_primary_25 p_x_md p_y_sm br_xs">
                    <Typography className="m_none" weight="400" scale="medium" scaleMobile="medium" component="p">
                        <strong>Tiempo restante:</strong> {Math.floor(remainingTime / (1000 * 60))} minutos con {Math.floor((remainingTime % (1000 * 60)) / 1000)} segundos
                    </Typography>
                    <Typography className="m_none" weight="400" scale="medium" scaleMobile="medium" component="p">
                        Puedes ingresar en cualquier momento dando <Link className="text_primary_300" to="/ingresar">click aquí.</Link>
                    </Typography>
                </div>
                {!completed ? (
                    <OzonFlyForm workDigitalPlatforms={workDigitalPlatforms} complete={() => setCompleted(true)} />
                ) : (
                    <OzonFlyFormCompleted />
                )}
            </div>}
            {(remainingTime <= 0) && <div className="m_y_lg_mobile m_y_xxxl_desktop m_x_auto w_600_px_desktop">
                <AuthCard
                    title="El token ha expirado"
                    subtitle={<p>Te invitamos a seguir con el proceso ingresando a Mi Cuenta</p>}
                    buttonTitle="Ingresar a Mi Cuenta"
                    buttonAction={() => navigate("/ingresar")}
                />
            </div>}
        </div>
    ) : (
        <div className="dso_container m_y_xxl" style={{ minHeight: "75vh", maxWidth: 1440 }}>
            Cargando...
        </div>
    );
};
