import { Button, ProgressBar, Typography } from "design_system/src";
import { fetchGetFinancialForm } from "helpers/fetchFormCrediticio";
import moment from "moment";
import ReactGA from "react-ga4";


import {
  getCurrentForm,
  transformFinancialFormObj,
  useFinancialForm,
} from "hooks/useGetFinancialForm";
import { FinancialFormStatus } from "models/financialForm.interface";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "store";
import { logout } from "store/actions/user";
import { TCredit, TCreditTotals } from "models/credit.interface";
import { fetchGetCreditTotals, fetchGetIdCredit } from "helpers/fetchMiCuenta";
import { TDataozoner } from "models/ozoner.interface";
import {
  CreditPaymentStatus,
  TCreditPayment,
} from "models/credit-payment.interface";
import { formatPrice } from "helpers/formatPrice";
import { WhatsappButton } from "./components/whatsappButton/WhatsappButton";

import "./miCuentaScreen.scss";

moment.locale("es");

export const MiCuentaScreen = () => {
  const navigate = useNavigate();
  const [financialForm, setFinancialForm] = useFinancialForm();
  const [selectedCredit, setSelectedCredit] = useState<TCredit>();
  const [credits, setCredits] = useState<TCredit[]>([]);
  const [ozoner, setOzoner] = useState<TDataozoner>();
  const [payments, setPayments] = useState<TCreditPayment[]>([]);
  const [creditTotals, setCreditTotals] = useState<TCreditTotals>();
  const [nextPayment, setNextPayment] = useState<TCreditPayment>();
  const defaultMoto =
    "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/generic-moto.png";

  useLayoutEffect(() => {
    if (financialForm) {
      fetchGetFinancialForm(financialForm?._id).then(async (data) => {
        setFinancialForm(transformFinancialFormObj(data.ozoner));
        const currentForm = getCurrentForm(data.ozoner.financialForm);

        setOzoner(data.ozoner);

        if (currentForm.status === FinancialFormStatus.COMPLETED) {
          ReactGA.event("VIEW_MyAccountCredit", {
            category: "VIEW_MyAccountCredit",
            label:"page view for credit info view",
          });

          fetchGetIdCredit(data.ozoner._id)
            .then((creditsResponse: TCredit[]) => {
              setCredits(creditsResponse);
              const currentCredit = creditsResponse.filter(
                (credit) => credit.financialFormId === currentForm._id
              )[0];
              const validPayments = currentCredit.payments.filter(
                (payment) => payment.status !== CreditPaymentStatus.DELETED
              );
              setPayments(validPayments);
              setSelectedCredit(currentCredit);
              setNextPayment(
                validPayments
                  .filter(
                    (payment) => payment.status !== CreditPaymentStatus.PAID
                  )
                  .sort((a, b) => a.quota - b.quota)[0]
              );
              return fetchGetCreditTotals(currentCredit._id);
            })
            .then((totals: TCreditTotals) => {
              setCreditTotals(totals);
            });

          return;
        }
        if (currentForm.status === FinancialFormStatus.PENDING) {
          navigate("/mi-cuenta/documentos");
          return;
        }

        if (
          !(
            currentForm.status === FinancialFormStatus.CANCELED ||
            currentForm.status === FinancialFormStatus.EXPIRED
          )
        ) {
          navigate("/mi-cuenta/solicitud");
          return;
        }

        store.dispatch(logout());
        navigate("/ingresar");
      });
    }
  }, []);


  const openPayPlatform = ()=>{
    ReactGA.event("CTA_MyAccount_go_to_moonflow_account", {
      category: "MyAccount",
      label: "click in CTA to open moonflow dashboard",
    });
    window.open(process.env.REACT_APP_PAYMENT_PLATFORM_URL, "_blank");

  };

  return selectedCredit ? (
    <div
      className="dso_container p_t_xxxl_desktop p_t_xl_mobile"
    >
      <Typography
        weight="600"
        scale="large"
        scaleMobile="medium"
        className=" w_100_per text_center_mobile m_b_xl_mobile m_b_md_desktop text_capitalize"
      >
        Bienvenid@ <strong>{ozoner?.name}</strong>
      </Typography>
      <div className="display_flex flex_col_mobile flex_gap_xl_desktop flex_gap_sm_mobile m_b_md">
        <div className="display_flex flex_col_mobile flex_gap_xxxl_desktop flex_gap_xl_mobile br_xs bg_primary_25 flex_grow_2  p_x_xxxl_desktop p_y_lg_desktop p_xs_mobile">
          <div className="flex_center_col flex_grow_2 flex_gap_xl_desktop flex_gap_md_mobile p_y_xxl_desktop">
            <img
              className="w_100_per h_200_px br_sm"
              style={{ objectFit: "cover" }}
              src={
                selectedCredit.vehicle.images?.length
                  ? selectedCredit.vehicle.images[0].url
                  : defaultMoto
              }
              alt={
                selectedCredit.vehicle.images?.length
                  ? selectedCredit.vehicle.images[0].name
                  : "ozon-mototcycle"
              }
            />
            <div className="flex_center_col flex_gap_xs">
              <Typography weight="400" scale="large">
                {selectedCredit.vehicle.brand.name}{" "}
                <strong>{selectedCredit.vehicle.model.name}</strong>
              </Typography>
              <Typography weight="400" scale="small">
                {selectedCredit.vehicle.details.year} |{" "}
                {selectedCredit.vehicle.cylindersCapacity}cc |{" "}
                {selectedCredit.vehicle.details.milage}km
              </Typography>
            </div>
          </div>
          <div className="flex_grow_8 flex_shrink_0 display_flex flex_col flex_gap_xs p_x_md_mobile">
            <Typography weight="400" scale="xsmall" className="text_right">
              Vehículo
            </Typography>
            <Typography weight="600" scale="large" className="text_right">
              {selectedCredit.vehicle.internalId}
            </Typography>
            {creditTotals ? (
              <>
                <Typography weight="400" scale="small">
                  Deuda
                </Typography>
                <Typography weight="600" scale="heading3">
                  ${formatPrice(creditTotals.totalDebt, "CO")}
                </Typography>
                <ProgressBar
                  containerClassName="bg_primary_100 h_sm"
                  fillClassName="bg_primary_300 h_sm"
                  percentage={
                    (Number(creditTotals.totalPaid) * 100) /
                    Number(creditTotals.totalCredit)
                  }
                />
                <div className="flex_center flex_justify_between border_b_1 border_neutral_600 border_b_dotted">
                  <Typography weight="400" scale="small">
                    Saldado:
                  </Typography>
                  <Typography weight="400" scale="small" className="m_b_md">
                    ${formatPrice(creditTotals.totalPaid, "CO")}
                  </Typography>
                </div>
              </>
            ) : null}
            <div className="flex_center flex_justify_between m_b_md">
              <div>
                <Typography weight="400" scale="small" className="m_b_xs">
                  Siguiente pago
                </Typography>
                <Typography weight="600" scale="large" className="">
                  ${formatPrice(selectedCredit.total)}
                </Typography>
              </div>
              {nextPayment ? (
                <div>
                  <Typography
                    weight="400"
                    scale="small"
                    className="text_right m_b_xs"
                  >
                    Fecha máxima de pago
                  </Typography>
                  <Typography weight="600" scale="large" className="text_right">
                    {moment.utc(nextPayment.issueDate).format("DD MMM, YYYY")}
                  </Typography>
                </div>
              ) : null}
            </div>
            <Button scale="small" className="m_b_md" onClick={openPayPlatform}>Pagar cuota semanal</Button>
          </div>
        </div>
        <div className="flex_grow_1 br_xs bg_neutral_300 p_xl_desktop p_y_xl_mobile p_x_md_mobile pos_relative">
          <Typography weight="400" scale="small" className="text_center m_b_xl">
            Pagos <strong>recientes</strong>
          </Typography>
          <div className="pos_absolute_desktop h_80_per_desktop overflow_y_scroll w_100_per_desktop p_x_xl_desktop p_x_md_mobile payments">

          {payments
            .filter((payment) => payment.status === CreditPaymentStatus.PAID)
            .sort((a, b) => b.quota - a.quota)
            .map((payment) => (
              <div className="flex_center flex_justify_between border_b_1 border_neutral_600 border_b_dotted p_y_md">
                <Typography weight="600" scale="small" className="">
                  {payment.quota}
                </Typography>
                <Typography weight="400" scale="small" className="">
                  {moment.utc(payment.issueDate).format("DD MMM, YYYY")}
                </Typography>
                <Typography weight="400" scale="small" className="">
                  ${formatPrice(payment.total, "CO")}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div/>
  );
};
