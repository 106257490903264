import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useFormikContext } from "formik";
import {
  Stepper,
  StepperBody,
  StepperSteps,
  StepperCountList,
  StepperFooter,
  Step,
} from "design_system/src";
import { getSimulatorBrands } from "helpers/fetchBrands";

import { TBrand } from "models/brand.interface";
import { OzocioStep1 } from "./step1/OzocioStep1";
import { OzocioStep2 } from "./step2/OzocioStep2";
import { OzocioStep3 } from "./step3/OzocioStep3";
import { OzocioStep4 } from "./step4/OzocioStep4";
import { OzocioStep5 } from "./step5/OzocioStep5";
import { OzocioStep6 } from "./step6/OzocioStep6";
import { OzocioStep7 } from "./step7/OzocioStep7";
import { OzocioStepperFormValues } from "../OzocioFormScreen";

type gaEventsProps = {
  category: string;
  label: string;
}

const gaEvents = [
  {
    name: "VIEW_Ozocio_year_vehicle",
    category: "Ozocio",
    label: "page view to add vehicle year view",
  },
  {
    name: "VIEW_Ozocio_km_vehicle",
    category: "Ozocio",
    label: "page view to add vehicle km view",
  },
  {
    name: "VIEW_Ozocio_km_other_vehicle",
    category: "Ozocio",
    label: "page view to add vehicle km other view",
  },
  {
    name: "VIEW_Ozocio_about_vehicle",
    category: "Ozocio",
    label: "page view to add brand, cilynders, module view",
  },
  {
    name: "VIEW_Ozocio_sell_reason_ozocio",
    category: "Ozocio",
    label: "page view to write sell reason",
  },
  {
    name: "VIEW_Ozocio_info_ozocio",
    category: "Ozocio",
    label: "page view to the info ozocio view",
  },
  {
    name: "VIEW_Ozocio_complete_offer_view",
    category: "Ozocio",
    label: "page view to the complete offer form view",
  },
];

export const OzocioStepper = () => {
  const [currentStep, setCurrenStep] = useState<number>(0);
  const [brands, setBrands] = useState<TBrand[] | []>([]);
  const { values } = useFormikContext<OzocioStepperFormValues>();
  const otro = values.step2.mileage === 999999999;

  const initBrands = async () => getSimulatorBrands();

  useEffect(() => {
    initBrands().then((res) => setBrands(res));
  }, []);

  const onChange = (step: any) => {
    setCurrenStep(step);
  };

  useEffect(() => {
    const event = gaEvents[otro || (!otro && currentStep < 2) ? currentStep : currentStep + 1 ];
    if (event) {
      ReactGA.event(event.name, {
        category: event.category,
        label: event.label,
      });
    }
  }, [currentStep]);

  return (
    <Stepper startStep={0} onChange={onChange}>
      <StepperBody>
        <StepperSteps >
          <Step value="1" name="Step">
            <OzocioStep1 />
          </Step>
          <Step value="2" name="Step">
            <OzocioStep2 />
          </Step>
          {otro && (
            <Step value="3" name="Step">
              <OzocioStep3 />
            </Step>
          )}
          <Step value={otro ? "4" : "3"} name="Step">
            <OzocioStep4 brands={brands} />
          </Step>
          <Step value={otro ? "5" : "4"} name="Step">
            <OzocioStep5 />
          </Step>
          <Step value={otro ? "5" : "4"} name="Step">
            <OzocioStep6 />
          </Step>
          <Step value={otro ? "6" : "5"} name="Step">
            <OzocioStep7 />
          </Step>
        </StepperSteps>
      </StepperBody>
      <StepperFooter>
        <StepperCountList />
      </StepperFooter>
    </Stepper>
  );
};
