/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum SALE_PRICE_WEEKS {
    WEEKS_1 = 1,
    WEEKS_52 = 52,
    WEEKS_78 = 78,
    WEEKS_104 = 104,
    WEEKS_156 = 156,
}

export enum VEHICLE_SORT_TYPE {
    PROMOTION = "promotion",
    HIGHER_PRICE = "higherPrice",
    LOWER_PRICE = "lowerPrice",
    HIGHER_CC = "higherCC",
    LOWER_CC = "lowerCC",
    HIGHER_KM = "higherKM",
    LOWER_KM = "lowerKM",
}
