/* eslint-disable no-unused-vars */
import React from "react";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";

type StepIndicatorProps = {
    currentNumber: string;
    isActive?: boolean;
    setStepperBody?: any;
    setActiveStep?: any;
    setCurrentSteps?: any;
    setRemainingSteps?: any;
    steps?: any;
    isClickeable?: boolean;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
                                                         currentNumber,
                                                         isActive,
                                                         setStepperBody,
                                                         setActiveStep,
                                                         setCurrentSteps,
                                                         isClickeable,
                                                         setRemainingSteps,
                                                         steps,
                                                     }) => {

    const useStyles = makeStyles(() => ({
        circleWrapper: {
            background: isActive ? "rgb(252,118,9)" : "rgb(244,244,244)",
            borderRadius: "50%",
            color: isActive ? "white" : "rgb(133,144,151)",
            display: "inline-block",
            lineHeight: "25px",
            marginRight: "5px",
            textAlign: "center",
            width: "25px",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.25); "
        }
    }));
    const classes = useStyles();


    const setCursor = (): string => {
        if (!isClickeable) {
            return "default";
        }
        return "pointer";

    };



    const handleOnClick = (currentSelectedStep: string) => {
        if (!isClickeable) return;

        const totalStepsNumber = steps.map((_:any,idx:number) => idx+1);
        const currentClickeableSteps = totalStepsNumber.filter((step:number) => step <= parseInt(currentSelectedStep, 10));
        const remainingClickeableSteps = totalStepsNumber.filter((step:number) => step > parseInt(currentSelectedStep, 10));


        if (setStepperBody) {
            setStepperBody(steps[+currentSelectedStep-1].component);
            setActiveStep(+currentNumber -1);
            setCurrentSteps(currentClickeableSteps);
            setRemainingSteps(remainingClickeableSteps);

        }
    };


    return (
        <Box className={classes.circleWrapper} style={{cursor: setCursor()}}
             onClick={()=>handleOnClick(currentNumber)}
        >
            {currentNumber}
        </Box>

    );
};

export default StepIndicator;
