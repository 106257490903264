import ReactGA from "react-ga4";
import React, { FC, ReactElement } from "react";
import { Input, Button, Typography } from "design_system/src";

type AuthCardLinkProps = {
    title: string;
    buttonTitle: string;
    buttonAction: any;
};

type AuthCardProps = {
    title: string;
    subtitle: ReactElement;
    form?: ReactElement;
    formik?: any;
    errorMessage?: string;
    buttonTitle: string;
    buttonAction: any;
    link?: AuthCardLinkProps;
    loading?: boolean;
};

export const AuthCard:FC<AuthCardProps> = ({
    title,
    subtitle,
    form,
    formik,
    errorMessage,
    buttonTitle,
    buttonAction,
    link,
    loading
}) => {

    return <div className="shadow_medium br_xs p_lg_mobile p_xl_desktop p_y_xxxl_desktop">
        <div className="m_x_auto w_70_per_desktop">
            <div className="m_y_md">
                <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
                    {title}
                </Typography>
                <Typography className="m_y_none text_center" weight="400" scale="medium" scaleMobile="medium" textColor="neutral_700">
                    {subtitle}
                </Typography>
            </div>
            <div className="m_y_md">
                {(form && formik) ? (
                    <form onSubmit={formik.handleSubmit}>
                        <div className="m_b_md">{form}</div>
                        {errorMessage && <Typography
                            scale="small"
                            weight="400"
                            className="bg_red_200 text_red_300 p_sm br_xxs m_b_md"
                        >
                            {errorMessage}
                        </Typography>}
                        <Button className="w_100_per" scale="small" onClick={buttonAction} type="submit" disabled={!(formik.isValid && formik.dirty) || loading}>
                            <Typography weight="600" scale="medium" scaleMobile="medium">
                                {loading ? "Cargando" : buttonTitle}
                            </Typography>
                        </Button>
                    </form>
                ) :(
                    <Button className="w_100_per" scale="small" onClick={buttonAction} disabled={loading}>
                        <Typography weight="600" scale="medium" scaleMobile="medium">
                            {loading ? "Cargando" : buttonTitle}
                        </Typography>
                    </Button>
                )}
            </div>
            {link && <div className="m_y_md">
                <Typography className="m_y_none text_center" weight="400" scale="medium" component="p" textColor="neutral_700">
                    {link.title}
                </Typography>
                <Button className="w_100_per p_y_none" scale="small" variant="ghost" onClick={link.buttonAction}>
                    <Typography weight="600" scale="medium" scaleMobile="medium">
                        {link.buttonTitle}
                    </Typography>
                </Button>
            </div>}
        </div>
    </div>;
};
