/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import CreditCalculationStepperView from "./CreditCalculationStepperView";
import { CreditProvider } from "./context/context";
import useTrackPixelOnMount from "../../hooks/FacebookPixel/useTrackPixelOnMount";

const CreditCalculationWithProvider = () => (
  <CreditProvider>
    <CreditCalculation />
  </CreditProvider>
);

const CreditCalculation = () => {
  const navigate = useNavigate();

  useTrackPixelOnMount("Subscribe");

  const scrollTop = () => {
    window.focus();
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    document.title = "Estudio financiero en Ozon";
    scrollTop();
  }, [navigate]);

  return (
    <div className="dso_container">
      <CreditCalculationStepperView />
    </div>
  );
};

export default CreditCalculationWithProvider;
