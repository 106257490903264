import { Dispatch } from "redux";
import { types } from "../types/types";

export const setUserAuthTokens = (jwt: string, refreshToken?: string) => {
  return {
    type: types.user,
    payload: {
      jwt,
      refreshToken,
      tempToken: "",
    },
  };
};

export const local = (user: any) => {

  // eslint-disable-next-line no-param-reassign
  delete user.password;
  return {
    type: types.userDt,
    payload: {
      user,
    },
  };
};

export const setTempToken = (tempToken: string) => {
  // eslint-disable-next-line no-param-reassign
  if (tempToken !== "") {
    localStorage.setItem("tempToken", tempToken);
  } else {
    localStorage.removeItem("tempToken");
  }
  return {
    type: types.temp,
    payload: {
      tempToken,
    },
  };
};

export const logout = () => (dispatch: Dispatch) => {
  localStorage.removeItem("current-financial-form");
  localStorage.removeItem("tempToken");
  dispatch({
    type: types.logout,
  });
};
