/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable arrow-body-style */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from "react";

import "./Popup.scss";

interface PopupProps {
  open: boolean;
  setOpen?:any;
  children: React.ReactNode;
  onClick?: any;
  placementY?: "top" | "bottom" | "top-inset" | "bottom-inset";
  placementX?:"right" | "left" | "right-inset" | "left-inset"
  className?: string;
}
const Popup: FC<PopupProps> = ({
  open,
  setOpen,
  children,
  placementY = "bottom",
  placementX = "left-inset",
  className = "",
  onClick = () => {},
}) => {
  return (
    <>
    {setOpen &&(
      <div
        aria-labelledby="backdrop"
        className={`backdrop_popup ${open ? "open" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        onKeyPress={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        role="button"
        tabIndex={0}
      />
    )}
    <div
      className={`dso_popup ${
        open ? "open" : ""
        } shadow_hard ${className} ${placementY} ${placementX}`}
      onClick={(e)=>{e.stopPropagation();onClick();}}
    >
      {children}
    </div>
    </>
  );
};

export default Popup;
