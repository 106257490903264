import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, Link } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import { Input, Modal, Typography, Button } from "design_system/src";

import { fetchCURP, fetchLogin } from "helpers/fetchMiCuenta";
import { useFinancialForm, transformFinancialFormObj } from "hooks/useGetFinancialForm";
import { AuthCard } from "../AuthCard";


const curpRegex =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

const passwordRegex =
    /(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

const curpValidationSchema = yup.object({
    curp: yup
        .string()
        .required("Campo requerido")
        .matches(curpRegex, "CURP inválido"),
});

const loginValidationSchema = yup.object({
    password: yup
        .string()
        .required("Campo requerido")
        .min(6, "Mínimo 6 caracteres")
        .max(50, "Máximo 50 caracteres")
        .matches(passwordRegex, "La contraseña debe tener al menos un letra en mayúscula, una en minúscula y un número"),
});

export const LoginScreen = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [secondStep, setSecondStep] = useState<boolean>(false);
    const [showCurpModal, setShowCurpModal] = useState<boolean>(false);
    const [CURP, setCURP] = useState<string>("");
    const [EMAIL, setEMAIL] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [financialForm, setFinancialForm] = useFinancialForm();

    const curpFormik = useFormik({
        initialValues: {
            curp: "",
        },
        validationSchema: curpValidationSchema,
        onSubmit: ({ curp }) => {
            setLoading(true);
            fetchCURP(curp)
                .then(async ({ email, exist, isAccountActive, financialFormStatus }) => {
                    if (exist && financialFormStatus !== null) {
                        if (isAccountActive) {
                            setLoading(false);
                            setCURP(curp);
                            setEMAIL(email);
                            setSecondStep(true);
                        } else {
                            navigate("/activar-cuenta", {state:{ email, curp }});
                        }
                    } else {
                        setLoading(false);
                        setShowCurpModal(true);
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                });
        },
    });

    const loginFormik = useFormik({
        initialValues: {
            password: "",
        },
        validationSchema: loginValidationSchema,
        onSubmit: ({ password }) => {
            setLoading(true);
            fetchLogin(CURP, password)
                .then(async (response) => {
                    setFinancialForm(transformFinancialFormObj(response.ozoner));
                    navigate("/mi-cuenta");
                    setLoading(false);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e.statusCode === 401) {
                        setErrorMessage("Credenciales inválidas");
                    }
                });
        },
    });

    useEffect(() => {
        ReactGA.event("VIEW_MyAccount_login", {
            category: "VIEW_MyAccount_login",
            label: "page view to login with curp",
        });
    }, []);

    return <div className="dso_container" style={{ minHeight: "75vh" }}>
        <div className="m_y_lg_mobile m_y_xxxl_desktop m_x_auto w_600_px_desktop">
            {secondStep ? (<AuthCard
                title="Ingreso"
                subtitle={<p>Ingresa tu <strong>contraseña</strong></p>}
                formik={loginFormik}
                form={<Input
                    title={`CURP: ${CURP}`}
                    name="password"
                    type="password"
                    placeholder="Ingresa tu contraseña"
                    onBlur={loginFormik.handleBlur}
                    value={loginFormik.values.password}
                    onChange={(e) => {
                        setErrorMessage(undefined);
                        loginFormik.handleChange(e);
                    }}
                    error={!!loginFormik.errors.password && !!loginFormik.touched.password}
                    subtitle={loginFormik.touched.password ? loginFormik.errors.password : ""}
                />}
                errorMessage={errorMessage}
                buttonTitle="Entrar"
                buttonAction={() => {}}
                link={{
                    title: "¿Olvidaste tu contraseña?",
                    buttonTitle: "Ingresa aquí",
                    buttonAction: () => navigate("/recuperar-contrasena", {state:{
                        curp: CURP,
                        email: EMAIL
                    }})
                }}
                loading={loading}
            />) : (<AuthCard
                title="Ingreso"
                subtitle={<p>Ingresa tu <strong>CURP</strong> para conocer el estado de tu solicitud</p>}
                formik={curpFormik}
                form={<Input
                    title=""
                    name="curp"
                    type="text"
                    placeholder="Ingresa tu CURP"
                    value={curpFormik.values.curp}
                    onChange={curpFormik.handleChange}
                />}
                buttonTitle="Enviar"
                buttonAction={() => {}}
                link={{
                    title: "¿No tienes una solicitud?",
                    buttonTitle: "Ingresa aquí",
                    buttonAction: () => navigate("/financia-tu-moto")
                }}
                loading={loading}
            />)}

            <Modal open={showCurpModal} setOpen={setShowCurpModal} className="">
                <Typography scale="large" weight="400" className="m_b_sm text_center">
                    <strong>No se encuentra una solicitud activa</strong> con el CURP proporcinado.
                </Typography>
                <Typography scale="small" weight="400" className="m_b_xl text_center">
                    Para continuar, debes diligenciar el formulario de solicitud de crédito
                </Typography>
                <Link
                    to="/financia-tu-moto"
                    className=""
                    >
                    <Button
                        variant="principal"
                        scale="small"
                        className="w_100_per"
                    >
                        Solicita tu crédito ya
                    </Button>
                </Link>
            </Modal>
        </div>
    </div>;
};
