import Swal from "sweetalert2";
import { FinancialFormStatus } from "models/financialForm.interface";
import { PersonalReferences } from "views/ozonFly/ozonFlyForm/OzonFlyForm";
import { FinancialFormErrors } from "enums/financialFormErrors.enum";
import { PandaboardApiInstance } from "./api";

interface FetchUserData {
  email: string;
  name: string;
  phone?: string;
  curp?: string;
  search?: boolean;
  address?: string;
  status?: string;
  statusHistoric?: any[];
}

export type UserDocument = {
  docs: any[];
  type: string;
};

export interface CreditFormResponseData {
  vehicleId?: string;
  creditTime?: number;
  workInDigitalPlatforms: boolean;
  platforms: (number | string)[];
  authDataTreatment: boolean;
  monthlyIncome: number;
  monthlySpending: number;
  childrenCount: number;
  dependantsCount: number;
  group: string;
  companyName: string;
  economicActivity: string;
  educationalLevel: string;
  position: string;
  civilStatus: string;
  livesWith: [];
  name: string;
  firstLastName: string;
  secondLastName: string;
  email: string;
  phone: string;
  address: string;
  gender: string;
  day: string;
  month: string;
  year: string;
  documents: UserDocument[];
  personal_references: PersonalReferences;
  curp: string;
  _id: string;
  user: string;
  assets: string[];
  otherAsset: string;
  status?: string;
}

export interface FetchValidateCurp {
  exist: boolean;
  email?: string;
  financialFormStatus?: FinancialFormStatus;
  isAccountActive?: boolean;
}

export interface FetchUserResponse {
  token: string;
  refresh_token: string;
  user: {
    confirmed: boolean;
    blocked: boolean;
    type: string;
    verified: boolean;
    name: string;
    mobile_phone: string;
    email: string;
    city: string;
    address: string;
    termsAgreed: boolean;
    username: string;
    provider: string;
    paymentData: any;
    createdAt: string;
    updatedAt: string;
    role: {
      name: string;
      description: string;
      type: string;
      id: string;
    };
    subscriptions: any[];
    credit_cards: any[];
    vehicles: any[];
    waitlists: any[];
    financialForm: CreditFormResponseData[];
    id: string;
  };
}

export const fetchCheckIn = async (data: FetchUserData) =>
  PandaboardApiInstance.post<FetchUserResponse>("auth-client/checkin", data)
    .then((res) => res.data)
    .catch((err: any) => {
      if (err.response && err.response.data?.message === FinancialFormErrors.EMAIL_ALREADY_EXISTS) {
        Swal.fire({
          text: "El correo se encuentra vinculado otro CURP.",
          title: "¡TU CORREO YA ESTÁ REGISTRADO!",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "bg_primary_300 w_90_per",
            title: "text_neutral_800",
            container: "text_neutral_700",
          },
        });
      }
      throw err.response.data;
    });

export const fetchLogin = async (email: string, password: string) =>
  PandaboardApiInstance.post("auth-client/login", { email, password })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchRecoverPassword = async (email: string) =>
  PandaboardApiInstance.post("auth-client/recover-password", { email })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchValidateCurp = async (curp: string) =>
  PandaboardApiInstance.post<FetchValidateCurp>("auth-client/validate-ozoner", { curp })
    .then((res) => {
      const {
        data: { exist, email, financialFormStatus },
      } = res;
      if (exist && financialFormStatus) {
        Swal.fire({
          text: `Este CURP ya se encuentra vinculado a una solicitud con el correo ${email}, ingresa un CURP valido.`,
          title: "¡TU CURP YA ESTÁ REGISTRADO!",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "bg_primary_300 w_90_per",
            title: "text_neutral_800",
            container: "text_neutral_700",
          },
        });
      }
      return !(exist && financialFormStatus);
    })
    .catch((err: any) => {
      if (err.response && err.response.status === 400) {
        Swal.fire({
          text: "¡CURP invalido!",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
      throw err.response.data;
    });

export const fetchValidateOzoner = async (data: { email: string, curp?: string }) =>
  PandaboardApiInstance.post<FetchValidateCurp>("auth-client/validate-ozoner", data)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });
