import _ from "lodash";
import { PersonalReference } from "views/ozonFly/ozonFlyForm/OzonFlyForm";
import { CalculationStepperFormValues } from "../views/creditCalculation/CreditCalculationStepperView";
import { DigitalPlatformsFormValues } from "../components/creditDigitalPlatforms/CreditDigitalPlatforms";
import { PandaboardApiInstance } from "./api";
import { Utm, getUtmObject } from "./queryParams";

export interface FormCrediticioRequest {
  creditCalculation: CalculationStepperFormValues;
  digitalPlatforms: DigitalPlatformsFormValues;
  vehicleId?: number | string;
  creditTime?: number;
  advancedMoney?: number;
  approved?: boolean;
  score: number;
  city: string;
  lat?: number;
  long?: number;
  utm?: Utm;
}

export interface FormCrediticioRequestSaleForce {
  oid: string;
  name: string;
  email: string;
  Telefono: string;
  origin: string;
  subject: string;
  description: string;
}

const formatfetchFormCrediticioData = (data: FormCrediticioRequest) => {
  // POR REQUERIMIENTO DE PANDABOARD HAY QUE FORMATEAR LOS ASSETS COMO UN STRING SEPARADOS POR COMAS
  const dataFormatted = { ...data };
  // delete dataFormatted.creditCalculation.assetsStep.otherSelected;
  return _.set(
    dataFormatted,
    "creditCalculation.assetsStep.joinedAssets",
    `${dataFormatted.creditCalculation.userStatusStep.assets
      .filter((e) => e !== "Otros")
      .join(",")}${
      dataFormatted.creditCalculation.userStatusStep.otherAsset
        ? `,${dataFormatted.creditCalculation.userStatusStep.otherAsset}`
        : ""
    }`
  );
};

export const fetchFormCrediticio = async (data: FormCrediticioRequest) => {
  const utm: Utm | undefined = getUtmObject();
  return PandaboardApiInstance.post(
    "financial-form",
    formatfetchFormCrediticioData({ ...data, utm }),
    { requireAuth: true }
  )
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

export const fetchFormCrediticioUpdatePalenca = async () =>
  PandaboardApiInstance.put("financial-form/palenca", {}, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export interface OzonerDocumentRequest {
  ozonerId: string;
  formData?: FormData;
  documentName?: string;
  documentId?: string;
}

export const fetchOzonerAddDocument = async ({
  ozonerId,
  formData,
  documentName,
}: OzonerDocumentRequest) =>
  PandaboardApiInstance.put(`ozoner/wp/${ozonerId}/${documentName}`, formData, {
    requireAuth: true,
  })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchOzonerDeleteDocument = async ({
  ozonerId,
  documentId,
}: OzonerDocumentRequest) =>
  PandaboardApiInstance.delete(`ozoner/wp/${ozonerId}/document/${documentId}`, {
    requireAuth: true,
  })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export interface FormCrediticioAddDocumentRequestDelete {
  documentId: string;
}

export interface FormCrediticioAddDocumentRequestPut {
  formData: FormData;
  documentName: string;
}

export const fetchFormCrediticioAddDocument = async ({
  formData,
  documentName,
}: FormCrediticioAddDocumentRequestPut) =>
  PandaboardApiInstance.put(
    `financial-form/documentWP/${documentName}`,
    formData,
    {
      requireAuth: true,
    }
  )
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchDeleteFormCrediticioAddDocument = async ({
  documentId,
}: FormCrediticioAddDocumentRequestDelete) =>
  PandaboardApiInstance.delete(`financial-form/documentWP/${documentId}`, {
    requireAuth: true,
  })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export interface FormCrediticioAddReferenceRequest {
  creditFormId: string;
  references: {
    personal_reference_1: PersonalReference;
    personal_reference_2: PersonalReference;
  };
}

export const fetchFormCrediticioAddReference = async ({
  creditFormId,
  references,
}: FormCrediticioAddReferenceRequest) =>
  PandaboardApiInstance.put(
    `financial-form/${creditFormId}/addReference`,
    references,
    { requireAuth: true }
  )
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchGetFinancialForm = async (id: string) =>
  PandaboardApiInstance.get(`financial-form/${id}`)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchFinancilFormAlertRetry = async (id: string) =>
  PandaboardApiInstance.post(
    `financial-form/${id}/alertRetry`,
    {},
    { requireAuth: true }
  )
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchNotifyValidateIdentity = async (
  email: string,
  internalId?: string
) =>
  PandaboardApiInstance.post(
    "financial-form/WP/validate-identity",
    { email, internalId },
    { requireAuth: true }
  )
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });
