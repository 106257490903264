/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */

import React from "react";
import Card from "@mui/material/Card";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload-ui-2.svg";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import { Grid, useMediaQuery } from "@mui/material";
import { Typography } from "design_system/src";
import Box from "@mui/material/Box";

import { Divider } from "../../views/DetailsView/NewDetailsView";
import { formatPrice } from "../../helpers/formatPrice";
import { prices } from "../../helpers/prices";
// import {TextBox} from "../cards/OzonCardVehicle";

type SkeletonCardProps = {
  showItems?: number;
  columnsPerCard?: number;
};

type CardItemProps = {
  columnsPerCard?: number;
};

const CardItem: React.FC<CardItemProps> = ({ columnsPerCard }) => {
  return (
    <Grid
      item
      xs={columnsPerCard}
      sm={columnsPerCard}
      md={columnsPerCard}
      lg={columnsPerCard}
    >
      <Card sx={{ m: 1, p: 2 }}>
        <Skeleton sx={{ height: 179 }} animation="wave" variant="rectangular" />
        <div style={{ display: "flex" }}>
          <Skeleton
            animation="wave"
            height={35}
            style={{ marginBottom: 0, marginRight: 10 }}
            width="30%"
          />
          <Skeleton
            animation="wave"
            height={35}
            style={{ marginBottom: 0 }}
            width="30%"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Skeleton
            animation="wave"
            height={35}
            style={{ marginBottom: 0 }}
            width="100%"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Skeleton
            animation="wave"
            height={35}
            style={{ marginBottom: 6 }}
            width="100%"
          />
        </div>
        <Skeleton
          sx={{ height: 100 }}
          animation="wave"
          variant="rectangular"
        />
      </Card>
    </Grid>
  );
};

const MobileCardItem: React.FC<CardItemProps> = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      rotateIcon: {
        animation: "$spin 2s linear infinite",
        width: "15px",
        marginRight: 15,
      },
      "@keyframes spin": {
        "0%": {
          transform: "rotate(360deg)",
        },
        "100%": {
          transform: "rotate(0deg)",
        },
      },
    })
  );
  const classes = useStyles();
  return (
    <Grid
      item
      className="dso_card m_t_lg  "
      xs={12}
      style={{ backgroundColor: "white" }}
    >
      <Grid container>
        <Grid item xs={6} style={{ padding: 10 }}>
          <Skeleton
            sx={{ height: 150, width: "100%", borderRadius: 3 }}
            animation="wave"
            variant="rectangular"
          />
        </Grid>
        <Grid item xs={6} style={{ padding: 10 }}>
          <div style={{ display: "flex" }}>
            <Skeleton
              animation="wave"
              height={30}
              style={{ marginBottom: 0, marginRight: 10 }}
              width="30%"
            />
            <Skeleton
              animation="wave"
              height={30}
              style={{ marginBottom: 0 }}
              width="30%"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Skeleton
              animation="wave"
              height={30}
              style={{ marginBottom: 0 }}
              width="100%"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Skeleton
              animation="wave"
              height={30}
              style={{ marginBottom: 6 }}
              width="100%"
            />
          </div>
          <Skeleton
            sx={{ height: 50 }}
            animation="wave"
            variant="rectangular"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const SkeletonCard: React.FC<SkeletonCardProps> = ({
  showItems = 6,
  columnsPerCard = 4,
}) => {
  const matchesXS = useMediaQuery("(min-width:600px)");

  const renderMobileSkeletonCards = (): any[] => {
    const skeletonCards: any[] = [];
    for (let i: number = 1; i <= showItems; i++) {
      skeletonCards.push(<MobileCardItem key={i} />);
    }
    return skeletonCards;
  };

  const renderSkeletonCards = (): any[] => {
    const skeletonCards: any[] = [];
    for (let i: number = 1; i <= showItems; i++) {
      skeletonCards.push(<CardItem key={i} columnsPerCard={columnsPerCard} />);
    }
    return skeletonCards;
  };

  return (
    <Grid container>
      {!matchesXS ? renderMobileSkeletonCards() : renderSkeletonCards()}
      <div className="center m_t_xxl">
        {!matchesXS ? (
          <> </>
        ) : (
          <Typography weight="600" scale="small">
            Cargando...
          </Typography>
        )}
      </div>
    </Grid>
  );
};

export default SkeletonCard;
