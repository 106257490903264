import React, { useEffect } from "react";
import {  HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/core/styles";
import { PersistGate } from "redux-persist/integration/react";
import CssBaseline from "@mui/material/CssBaseline";
import { client } from "apolloClient";
import { saveUtmQueryParamsToSession } from "helpers/queryParams";
import { persistor, store } from "./store";
import { RootRouter } from "./routers/index.router";
import { theme } from "./theme/theme";
import Meta from "./components/head/Meta";
import "react-toastify/dist/ReactToastify.css";

export const Ozon = () => {
  useEffect(() => {
    saveUtmQueryParamsToSession();
  }, []);

  return (
    <HelmetProvider>
      <Meta/>
      <ApolloProvider client={client}>
      <Provider store={store}>
        <CssBaseline />
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <RootRouter />
          </ThemeProvider>
        </PersistGate>
      </Provider>
      </ApolloProvider>
    </HelmetProvider>
    // router inside here, provider theme
  );
};
