/* eslint-disable jsx-a11y/label-has-associated-control */
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useFormikContext } from "formik";
import _ from "lodash";

import { Input, Typography } from "design_system/src";
import { OzocioStepperFormValues } from "views/ozocioForm/OzocioFormScreen";
import useValidateStep from "../useValidateStep";
import BackStepButton from "../BackStepButton/BackStepButton";
import NextStepButton from "../NextStepButton/NextStepButton";
import "./stylesstep5.scss";

export const OzocioStep5 = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<OzocioStepperFormValues>();
  const isValid = useValidateStep("step5");

  return (
    <div className="flex_center_col w_90_per">
      <Typography weight="600" scale="heading3" className="text_center">
        <span className="text_primary_300">Cuéntanos sobre </span>tu vehículo
      </Typography>
      <Typography weight="400" scale="small" className="text_center m_t_lg">
        ¿Por qué quieres vender tu vehículo?
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
        className="m_t_lg"
      >
        <Grid item xs={12} sm={12} md={12}>
          <Input
            className="m_r_xxxl_desktop w_100_per flex_grow_1"
            title="Motivo de la venta"
            type="textarea"
            name="step5.sellReason"
            value={_.get(values, "step5.sellReason")}
            onBlur={handleBlur}
            onChange={handleChange}
            subtitle={
              getFieldMeta("step5.sellReason").touched
                ? (_.get(errors, "step5.sellReason") as string)
                : undefined
            }
            error={
              !!_.get(errors, "step5.sellReason") &&
              getFieldMeta("step5.sellReason").touched
            }
          />
        </Grid>
      </Grid>
      <div className="m_t_xxxl w_300_px_desktop flex_center_col">
        <NextStepButton isValid={isValid} />
        <BackStepButton isValid />
      </div>
    </div>
  );
};
