/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Button, useWindowSize, Modal } from "design_system/src";
import { ReactComponent as Right } from "design_system/src/static/icons/chevron-right.svg";

import vehicleDesktopImg from "static/images/banner/banner_vehicle_desktop.png";
import vehicleImg from "static/images/banner/banner_vehicle.png";

import bannerBaseDesktopImg from "static/images/banner/banner_background_desktop_1.jpg";
import bannerBaseMobileImg from "static/images/banner/banner_background_1.jpg";

import specialBannerBaseDesktopImg from "static/images/banner/banner_background_desktop_2.jpg";
import specialBannerBaseImg from "static/images/banner/banner_background_2.jpg";

import uberLogo from "static/icons/uber_logo.png";
import didiLogo from "static/icons/didi_food.png";

import "./oneThousand.scss";
import { useDispatch } from "react-redux";
import {
  clearCreditVerificationCreditTimeAction,
  clearCreditVerificationVehicleAction,
} from "store/actions/creditVerification";

const uber = "UBER";
const didi = "DIDI";

export const OneThousandSection = () => {
  const { width: windowWidth } = useWindowSize();
  const targetDate = Date.parse(`${process.env.REACT_APP_TARGET_DATE_BANNER}`);
  const targetEndedDate = process.env.REACT_APP_TARGET_ENDED_DATE_BANNER
    ? Date.parse(`${process.env.REACT_APP_TARGET_ENDED_DATE_BANNER}`)
    : null;
  const [backgroundDesktopImage, setBackgroundDesktopImage] = useState<any>(bannerBaseDesktopImg);
  const [backgroundImage, setBackgroundImage] = useState<any>(bannerBaseMobileImg);
  const [showModal, setShowModal] = useState<boolean>(false);

  const dispatch = useDispatch();

  const clickToFinancialForm = (alliedCompany: string  | null = null) => {
    dispatch(clearCreditVerificationCreditTimeAction());
    dispatch(clearCreditVerificationVehicleAction());

    if (alliedCompany) {
      ReactGA.event("CTA_discount_delivery_drivers", {
        category: "Financial Form",
        label: "click modal of delivery drivers discount",
        value: alliedCompany
      });
    }
  };

  const changeBackground = (change: boolean) => {
    setBackgroundDesktopImage(change ? specialBannerBaseDesktopImg: bannerBaseDesktopImg);
    setBackgroundImage(change ? specialBannerBaseImg: bannerBaseMobileImg);
  };

  const checkDate = () => {
    const currentDate = new Date();
    if (!targetEndedDate) return false;
    return currentDate.getTime() >= targetDate && currentDate.getTime() <= targetEndedDate;
  };

  useEffect(() => {
    const currentDate = new Date();
    if (targetEndedDate && currentDate.getTime() < targetEndedDate) {
      changeBackground(checkDate());
      const interval = setInterval(() => {
        changeBackground(checkDate());
      }, 60000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, []);

  const renderButtons = () => {
    return (
      <div className="m_y_xl w_100_per">
        <div className={`w_100_per display_flex${windowWidth > 800 ? " flex_justify_start flex_gap_xl flex_align_end" : " flex_justify_center flex_col flex_align_start flex_gap_xs"}`}>
          <Link to="/financia-tu-moto" onClick={() => clickToFinancialForm()} className="w_100_per_mobile">
            <Button
              variant="principal"
              scale={windowWidth > 800 || windowWidth < 600 ? "small" : "small"}
              className="w_100_per_mobile"
            >
              <Typography weight="600" scale="medium">¡Solicita tu crédito ahora!</Typography>
            </Button>
          </Link>
          <Link to="/catalogo" className="w_100_per_mobile">
            <Button
              color="grey"
              orientation="right"
              scale={windowWidth > 800 || windowWidth < 600 ? "small" : "small"}
              icon={<Right />}
              className="w_100_per_mobile"
            >
              <Typography weight="600" scale="medium">Ver catálogo de motos</Typography>
            </Button>
          </Link>
        </div>
        <div className="w_100_per display_flex flex_col_mobile flex_align_center m_t_xl">
          <Typography weight={windowWidth > 600 ? "400" : "600"} scale="medium" textColor="neutral_0">¿Eres repartidor?</Typography>
          <Button
            color="primary"
            variant="ghost"
            orientation="right"
            scale={windowWidth > 800 || windowWidth < 600 ? "small" : "small"}
            icon={<Right />}
            className="w_100_per_mobile p_none"
            onClick={() => setShowModal(true)}
          >
            <Typography weight="600" scale="medium">¡Aprovecha este descuento!</Typography>
          </Button>
        </div>
      </div>
    );
  };

  const renderTextContent = () => {
    return (
      <div className="w_100_per">
        <Typography scale={windowWidth > 800 ? "display" : "heading1"} scaleMobile="heading1" weight="600" textColor="primary_300">Moto nueva,</Typography>
        <Typography scale={windowWidth > 800 ? "display" : "heading1"} scaleMobile="heading1"weight="600" textColor="neutral_0">crédito fácil.</Typography>
        <Typography scale={windowWidth > 800 ? "heading4" : "medium"} scaleMobile="medium" weight="400" textColor="neutral_0">Obtén tu crédito pre aprobado en solo <br />5 minutos, ¡Inicia tu proceso hoy!</Typography>
      </div>
    );
  };

  return (
    <section className="bannerSection">
      <div className="p_x_none pos_relative">
        <img
          src={backgroundImage}
          alt="Banner landing page"
          className="bannerBaseImg display_none_desktop w_100_per_mobile"
        />
        <img
          src={backgroundDesktopImage}
          alt="Banner landing page"
          className="bannerBaseImg display_none_mobile w_100_per_desktop"
        />
        <div className="bannerContainer p_x_none pos_absolute w_100_per_mobile w_100_per_desktop banner-buttons">
          <div className="display_flex flex_col_reverse_mobile flex_row_reverse_desktop flex_justify_between h_100_per_mobile h_100_per_desktop">
            <div className="w_50_per_desktop h_50_per_mobile h_100_per_desktop pos_relative display_flex flex_justify_end_desktop">
              <img
                src={windowWidth > 600 ? vehicleDesktopImg : vehicleImg}
                alt="Moto venom"
                className="vehicleImg"
              />
            </div>
            <div className="w_50_per_desktop h_50_per_mobile pos_relative">
              <div className="w_100_per pos_absolute h_100_per_desktop display_flex flex_col flex_justify_between_mobile flex_justify_center_desktop flex_align_center_mobile flex_align_end_desktop">
                <div className="display_flex flex_col w_80_per flex_align_center p_t_xl_mobile p_t_xxxl_desktop">
                  {renderTextContent()}
                  {renderButtons()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={showModal} setOpen={setShowModal} className="alliedCardModal">
        <Typography weight="600" scale="heading4" textColor="primary_300" className="text_center">Tenemos descuentos especiales para repartidores</Typography>
        <Typography weight="400" scale="medium" className="text_center">Selecciona en que plataforma te encuentras como repartidor:</Typography>
        <div className="display_flex flex_col_mobile flex_gap_md flex_align_center flex_justify_center m_t_md">
          <Link to={`/financia-tu-moto?alliedCompany=${uber}`} onClick={() => clickToFinancialForm(uber)} className="w_100_per_mobile alliedCard__link">
            <div className="alliedCard">
              <img
                src={uberLogo}
                alt="uber"
              />
              <Typography weight="600" scale="medium" className="text_center">Ahorra hasta $10,700</Typography>
            </div>
          </Link>
          <Link to={`/financia-tu-moto?alliedCompany=${didi}`} onClick={() => clickToFinancialForm(didi)} className="w_100_per_mobile alliedCard__link">
            <div className="alliedCard">
              <img
                src={didiLogo}
                alt="didi food"
              />
              <Typography weight="600" scale="medium" className="text_center">Ahorra hasta $7,700</Typography>
            </div>
          </Link>
        </div>
      </Modal>
    </section>
  );
};
