/* eslint-disable react/no-unused-prop-types */
import React, { FC, useCallback } from "react";
import "./styles.scss";
import useStepper from "../Hooks/useStepper";

interface Props {
  step: any;
  index: number;
}

const StepCountItem: FC<Props> = ({ index }) => {
  const { currentStep, setCurrentStep } = useStepper();

  const handleClick = useCallback(() => {
    if (index < currentStep) {
      setCurrentStep(index);
    }
  }, [currentStep, index, setCurrentStep]);

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={-1}
      className={`stepper-count-item ${
        index <= currentStep ? "completed" : ""
      }`}
    >
      <span className="text_xsmall_600">{index + 1}</span>
    </div>
  );
};

export default StepCountItem;
