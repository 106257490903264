/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";

import CardMedia from "@mui/material/CardMedia";

import { Typography } from "design_system/src";

import { useMediaQuery } from "@material-ui/core";

import { ReactComponent as GPS } from "design_system/src/static/icons/gps.svg";

import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { VEHICLE_STATUS } from "models/enums/vehicleStatus.enum";
import { TVehicle } from "../../models/vehicle.interface";
import { formatPrice } from "../../helpers/formatPrice";
import { prices } from "../../helpers/prices";
import { RootState } from "../../store";

import "./OzonCardVehicle.scss";
import HelperCardImg from "../../static/helperCard/helperCardImg.png";
import useVehicles from "../../hooks/useVehicles";

interface DiscountLabelProps {
  total: number;
  value: number;
}

const DiscountLabel: React.FC<DiscountLabelProps> = ({ total, value }) => {
  const [percentage, setpercentage] = React.useState<number>();
  React.useEffect(() => {
    setpercentage(() => 100 - (value * 100) / total);
  }, [value, total]);

  return (
    <Typography
      scale="xxsmall"
      weight="600"
      className="text_no_break"
      style={{ display: "flex", justifyContent: "center", alignItems: "start" }}
    >
      {`-${percentage?.toFixed()}% Desc.`}
    </Typography>
  );
};

type CardProps = {
  vehicle?: TVehicle;
  isHelperCard: boolean;
  triggerCard?: any;
};

const OzonCardVehicle: React.FC<CardProps> = ({
  vehicle,
  isHelperCard,
  triggerCard,
}) => {
  const navigate = useNavigate();

  const matchesXS = useMediaQuery("(min-width:600px)");
  const matchesLG = useMediaQuery("(min-width:1097px)");
  const [isHovering, setIsHovering] = useState(false);

  const {
    loading: vehiclesLoading,
    selectedFilters,
    error: vehiclesError,
    data: { vehicles, filters },
  } = useVehicles();

  const [creditTime, setCreditTime] = useState<number>();

  useEffect(() => {
    if(vehicle) {
      const credit =
        vehicle?.salePrices?.[vehicle.salePrices.length - 1]?.weeks !== undefined
          ? vehicle.salePrices[vehicle.salePrices.length - 1].weeks
          : vehicle.salePrices[0].weeks;
      setCreditTime(credit);
    }
  }, [vehicle, selectedFilters?.weeks]);

  const handleCardClick = () => {
    if (isHelperCard) {
      triggerCard();
      return;
    }
    navigate(`/catalogo/${vehicle?.internalId}}`);
  };
  const { country } = useSelector((state: RootState) => state.countryReducer);

  const isAvailable = vehicle?.status === VEHICLE_STATUS.AVAILABLE;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleChip = (cuota: number): boolean => {
    const exist = vehicle?.salePrices.find(
      (cuotaVehicle) => cuotaVehicle.weeks === cuota
    );
    return !!exist;
  };

  const handleCity = (city: string): string => {
    switch (city) {
      case "Guadalajara":
        return "GDL";

      case "Ciudad de México":
        return "CDMX";

      default:
        return "CDMX";
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const renderCardHelperBody = () => {
    return !matchesXS ? (
      <div
        className="display_flex"
        onClick={handleCardClick}
        style={{
          flexDirection: "column",
          justifyContent: "start",
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <img
            src={HelperCardImg}
            alt="thumbnail"
            style={{
              maxHeight: "80px",
              minWidth: "124px",
              height: "auto",
              width: "35%",
              marginLeft: "10px",
              marginRight: "25px",
              borderRadius: 10,
              marginBottom: 20,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Typography
              scale="large"
              weight="400"
              className="text_neutral_800 "
            >
              <span className="text_primary_300">¿Te ayudamos</span> a encontrar{" "}
              <span className="text_neutral_900">tu moto personalizada?</span>
            </Typography>
          </div>
        </div>
        <div style={{ borderBottom: "0.529801px solid #DEDEDE" }} />
        <div
          className="m_t_xs flex_gap_sm"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>
            <div className="display_flex" style={{ flexDirection: "column" }}>
              <div className="w_100_per bg_neutral_300 br_xs display_flex flex_center m_b_md">
                <Typography
                  scale="xsmall"
                  weight="600"
                  className="text_neutral_800 text_center "
                  style={{
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    padding: "5px 10px",
                  }}
                >
                  De manera fácil y rápida
                </Typography>
              </div>
              <div className="w_100_per bg_neutral_300 br_xs display_flex flex_center">
                <Typography
                  scale="xsmall"
                  weight="600"
                  className="text_neutral_800 text_center"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    padding: "5px 10px",
                  }}
                >
                  Sin dar enganche
                </Typography>
              </div>
            </div>
          </div>
          <div className="p_md flex_center w_60_per br_sm bg_primary_50">
            <Typography
              scale="large"
              weight="600"
              className="text_primary_300 text-center"
            >
              ¡Haz la prueba!
            </Typography>
          </div>
        </div>
      </div>
    ) : (
      <div style={{ padding: 10, height: "100%", display: "flex", flexDirection: "column" }} onClick={handleCardClick}>
        <div className="image-box">
          <CardMedia
            component="img"
            height="180"
            className=" br_xs  card_media_helper"
            image={HelperCardImg}
            alt="helper"
            sx={{ borderRadius: 2 }}
          />
        </div>

        <div>
          <Typography
            scale="medium"
            weight="600"
            className="text_neutral_800 m_y_xs"
          >
            <span className="text_primary_300">¿Te ayudamos</span>{" "}
            <span style={{ fontWeight: "normal" }}>a encontrar</span> tu moto
            ideal?
          </Typography>
        </div>

        <div>
          <div className="display_flex flex_gap_xs w_100_per m_b_xs">
            <div className="w_100_per bg_neutral_300 br_xs display_flex flex_center">
              <Typography
                scale="xsmall"
                weight="600"
                className="text_neutral_800 text_center p_y_xs"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Sin dar enganche
              </Typography>
            </div>
          </div>
          <div className="display_flex flex_gap_xs w_100_per">
            <div className="w_100_per bg_neutral_300 br_xs display_flex flex_center">
              <Typography
                scale="xsmall"
                weight="600"
                className="text_neutral_800 text_center p_y_xs"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                De manera fácil y rápida
              </Typography>
            </div>
          </div>
        </div>

        <div
          className="br_b_xs m_t_xs w_100_per"
          style={{
            backgroundColor: isHovering ? "#FDE9D3" : "#ecedef",
            flexGrow: 1
          }}
        >
          <Typography scale="medium" weight="400" className="text_center p_md">
            Si no estás seguro de{" "}
            <span style={{ fontWeight: "bold" }}>qué moto escoger,</span>{" "}
            <span style={{ fontWeight: "bold" }} className="text_primary_300">
              ¡te ayudamos!
            </span>
          </Typography>
        </div>
      </div>
    );
  };

  const renderCardVehicle = () => (
    //
    <Link to={`/catalogo/${vehicle?.internalId}`} className="custom_a">
      <div className="p_y_sm p_x_md display_flex flex_col_desktop flex_gap_xs pos_relative">
        <div className="image-box flex_basis_0_mobile flex_grow_2_mobile overflow_hidden_mobile">
          <figure className="m_none pos_relative">
            <CardMedia
              component="img"
              className="br_xs card_media_vehicle"
              image={vehicle?.images && vehicle?.images[0]?.url}
              alt="vehicle"
              sx={{ borderRadius: 2 }}
            />

            {vehicle?.hasDiscount() && (
              <div
                className="pos_absolute display_none_mobile bg_primary_300 br_xxs text_neutral_0 p_x_xs p_y_xxs"
                style={{ top: 0, right: 0 }}
              >
                <DiscountLabel
                  total={vehicle.getWeeklyPriceWithoutDiscount(creditTime)}
                  value={vehicle.getWeeklyPrice(creditTime)}
                />
              </div>
            )}
          </figure>
        </div>
        <div className="display_flex flex_col flex_basis_0_mobile flex_grow_3_mobile">
          <div className="p_y_xxs display_flex flex_wrap flex_gap_xxs">
            {vehicle?.hasDiscount() && (
              <div
                className="display_none_desktop bg_primary_300 br_xxs text_neutral_0 p_x_xs p_y_xxs"
                style={{ zIndex: 1, top: 0, right: 0 }}
              >
                <DiscountLabel
                  total={vehicle.getWeeklyPriceWithoutDiscount(creditTime)}
                  value={vehicle.getWeeklyPrice(creditTime)}
                />
              </div>
            )}

            {vehicle?.newVehicle &&
              vehicle.isPresale &&
              vehicle.newVehicle === true &&
              vehicle.isPresale === true && (
                <div className="display_flex flex_center">
                  <div className="br_xxs bg_green_300 p_y_xxxs p_x_xs">
                    <Typography
                      scale="xsmall"
                      weight="600"
                      className="text_neutral_100"
                    >
                      PRE-VENTA
                    </Typography>
                  </div>
                </div>
              )}
            {vehicle?.newVehicle && vehicle.newVehicle === true && (
              <div className="display_flex flex_center">
                <div className="br_xxs border_solid border_primary_300 border_1 text_primary_300 p_y_xxxs p_x_xs">
                  <Typography scale="xsmall" weight="600">
                    NUEVA
                  </Typography>
                </div>
              </div>
            )}
            {vehicle?.platedVehicle && vehicle.platedVehicle === true && (
              <div className="display_flex flex_center">
                <div className="br_xxs bg_primary_300 p_y_xxxs p_x_xs">
                  <Typography
                    scale="xsmall"
                    weight="600"
                    className="text_neural_0"
                  >
                    EMPLACADA
                  </Typography>
                </div>
              </div>
            )}
            {!((vehicle?.newVehicle &&
              vehicle.isPresale &&
              vehicle.newVehicle === true &&
              vehicle.isPresale === true ) || (
              vehicle?.newVehicle && vehicle.newVehicle === true) || (
              vehicle?.platedVehicle && vehicle.platedVehicle === true)) && (
                <div className="display_flex flex_center">
                  <div className="br_xxs bg_neutral_100 p_y_xxxs p_x_xs">
                    <Typography
                      scale="xsmall"
                      weight="600"
                      className="text_neutral_100"
                    >
                      Moto
                    </Typography>
                  </div>
                </div>
              )}

          </div>
          <div className="p_y_xxs">
            <Typography
              scale="medium"
              scaleMobile="xsmall"
              weight="600"
              className="m_none"
              component="h3"
            >
              {`${vehicle?.brand?.name} ${vehicle?.model?.name} ${
                vehicle?.cylindersCapacity
              }${vehicle?.suffix ? vehicle.suffix : ""}`}
            </Typography>
          </div>
          <div className="display_flex flex_wrap w_100_per p_y_xxs text_neutral_800">
            <Typography
              scale="xsmall"
              scaleMobile="xxsmall"
              weight="400"
              className="text_center text_no_break p_r_xxs m_r_xxs border_r_solid border_r_1"
            >
              {vehicle?.details?.year}
            </Typography>
            <Typography
              scale="xsmall"
              scaleMobile="xxsmall"
              weight="400"
              className="text_center text_no_break p_r_xxs m_r_xxs border_r_solid border_r_1"
            >
              {vehicle?.details?.milage === 0 && !vehicle?.newVehicle
                ? "KM pendiente"
                : `${vehicle?.details?.milage} Km`}
            </Typography>
            <Typography
              scale="xsmall"
              scaleMobile="xxsmall"
              weight="400"
              className="text_center text_no_break p_r_xxs m_r_xxs border_r_solid border_r_1"
            >
              {`${vehicle?.cylindersCapacity} CC`}
            </Typography>
            <Typography
              scale="xsmall"
              scaleMobile="xxsmall"
              weight="400"
              className="text_center text_no_break"
            >
              {handleCity(vehicle?.city?.name || "CDMX")}
            </Typography>
          </div>
          <div className="p_y_xxs w_100_per br_xs">
              <Typography
                scale="xsmall"
                scaleMobile="xxsmall"
                weight="400"
                component="p"
                className="m_none"
              >
                Desde:
              </Typography>
              <Typography
                scale="heading3"
                weight="600"
                scaleMobile="medium"
                component="p"
                className="m_none"
              >
                ${formatPrice(prices(vehicle?.getWeeklyPrice(creditTime)), "")}
              </Typography>
              <Typography
                scale="xsmall"
                scaleMobile="xxsmall"
                weight="400"
                component="p"
                className="m_none"
              >
                Semanales
              </Typography>
          </div>
        </div>
      </div>
    </Link>
  );
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      className="dso_card bg_neutral_0 cursor_pointer"
      style={{ height: "100%"}}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {!isHelperCard && vehicle ? renderCardVehicle() : renderCardHelperBody()}
    </div>
  );
};

export default OzonCardVehicle;
