import React, { useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, useLocation, Link } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import { Input, Modal, Typography, Button } from "design_system/src";

import { store } from "store";
import { logout } from "store/actions/user";
import { fetchCURP, fetchChangePassword, fetchOtpPassword, fetchOtpValidation } from "helpers/fetchMiCuenta";
import { AuthCard } from "../AuthCard";

const curpRegex =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

const passwordRegex =
    /(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

const curpValidationSchema = yup.object({
    curp: yup
        .string()
        .required("Campo requerido")
        .matches(curpRegex, "CURP inválido"),
});

const otpValidationSchema = yup.object({
    otp: yup
        .string()
        .matches(/^\d+$/, "Numero invalido")
        .required("Campo requerido"),
});

const passwordValidationSchema = yup.object({
    password: yup
        .string()
        .required("Campo requerido")
        .min(6, "Mínimo 6 caracteres")
        .max(50, "Máximo 50 caracteres")
        .matches(passwordRegex, "La contraseña debe tener al menos un letra en mayúscula, una en minúscula y un número"),
    passwordConfirmation: yup
        .string()
        .required("Campo requerido")
        .test(
            "",
            "Las contraseñas deben ser iguales",
            function (value) {
                return value === this.parent.password;
            }
        ),
});

interface AuthValues {
    curp?: string;
    email?: string;
    otpSended?: boolean;
    otpValid?: boolean;
    successfulActivation?: boolean;
}

export const RecoverPasswordScreen = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [showCurpModal, setShowCurpModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const resetChanges = () => {
        navigate("/recuperar-contrasena", {state:{}});
    };

    const curpFormik = useFormik({
        initialValues: {
            curp: "",
        },
        validationSchema: curpValidationSchema,
        onSubmit: ({ curp }) => {
            setLoading(true);
            fetchCURP(curp)
                .then(async ({ email }) => {
                    if (email) {
                        setLoading(false);
                        navigate("/recuperar-contrasena", {state:{ curp, email }});
                    } else {
                        setShowCurpModal(true);
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                });
        },
    });

    const sendOTP = (curp: string) => {
        setLoading(true);
        fetchOtpPassword(curp)
            .then(async () => {
                setLoading(false);
                navigate("/recuperar-contrasena", {state:{ ...state, otpSended: true, otpValid: false }});
            })
            .catch((e: any) => {
                setLoading(false);
            });
    };

    const otpFormik = useFormik({
        initialValues: {
            otp: "",
        },
        validationSchema: otpValidationSchema,
        onSubmit: ({ otp }) => {
            if (state?.curp) {
                setLoading(true);
                fetchOtpValidation(state?.curp, `${otp}`)
                    .then(async () => {
                        setLoading(false);
                        ReactGA.event("VIEW_MyAccount_new_password", {
                            category: "VIEW_MyAccount_new_password",
                            label: "page view to set new password to access account",
                        });
                        navigate("/recuperar-contrasena", {state:{ ...state, otpValid: true }});
                    })
                    .catch((e: any) => {
                        setLoading(false);
                        if (e.message === "OTP not valid") {
                            setErrorMessage("Código inválido");
                        } else if(e.message === "OTP expired") {
                            setErrorMessage("El código ha expirado");
                        }
                    });
            }
        },
    });

    const passwordFormik = useFormik({
        initialValues: {
            password: "",
            passwordConfirmation: "",
        },
        validationSchema: passwordValidationSchema,
        onSubmit: ({ password, passwordConfirmation }) => {
            setLoading(true);
            fetchChangePassword(password, passwordConfirmation)
                .then(async () => {
                    setLoading(false);
                    navigate("/recuperar-contrasena", {state:{ ...state, successfulActivation: true }});
                })
                .catch((e: any) => {
                    setLoading(false);
                    setErrorMessage("No fue posible el cambio de contraseña");
                });
        },
    });

    return <div className="dso_container" style={{ minHeight: "75vh" }}>
        <div className="m_y_lg_mobile m_y_xxxl_desktop m_x_auto w_600_px_desktop">
            {!(state?.curp && state?.email) && <AuthCard
                title="Recuperación de la cuenta"
                subtitle={<p>Ingresa tu <strong>CURP</strong> para continuar</p>}
                formik={curpFormik}
                form={<Input
                    title=""
                    name="curp"
                    type="text"
                    placeholder="Ingresa tu CURP"
                    onBlur={curpFormik.handleBlur}
                    value={curpFormik.values.curp}
                    onChange={curpFormik.handleChange}
                    error={!!curpFormik.errors.curp && !!curpFormik.touched.curp}
                    subtitle={curpFormik.touched.curp ? curpFormik.errors.curp : ""}
                />}
                buttonTitle="Enviar"
                buttonAction={() => {}}
                loading={loading}
            />}

            {(state?.curp && state?.email && !state?.otpSended && !state?.otpValid) && <AuthCard
                title="Recuperación de la cuenta"
                subtitle={<p>Enviaremos un mensaje a tu correo electrónico: {state?.email} con el código de recuperación</p>}
                buttonTitle="Enviar"
                buttonAction={() => sendOTP(state?.curp ?? "")}
                loading={loading}
                link={{
                    title: "",
                    buttonTitle: "Cancelar",
                    buttonAction: () => resetChanges()
                }}
            />}

            {(state?.curp && state?.email && state?.otpSended && !state?.otpValid) && <AuthCard
                title="Recuperación de mi cuenta"
                subtitle={<p>Ingresa el código enviado a tu correo electrónico. Por favor, verifica tu carpeta de spam si no has recibido el correo en tu bandeja de entrada.</p>}
                formik={otpFormik}
                form={<Input
                    title=""
                    name="otp"
                    type="text"
                    placeholder="Ingresa el código"
                    onBlur={otpFormik.handleBlur}
                    value={otpFormik.values.otp}
                    onChange={(e) => {
                        setErrorMessage(undefined);
                        otpFormik.handleChange(e);
                    }}
                    error={!!otpFormik.errors.otp && !!otpFormik.touched.otp}
                    subtitle={otpFormik.touched.otp ? otpFormik.errors.otp : ""}
                />}
                errorMessage={errorMessage}
                buttonTitle="Confirmar código"
                buttonAction={() => {}}
                loading={loading}
                link={{
                    title: "",
                    buttonTitle: "Reenviar código",
                    buttonAction: () => {
                        setErrorMessage(undefined);
                        otpFormik.setFieldValue("otp", "");
                        sendOTP(state.curp ?? "");
                    }
                }}
            />}

            {(state?.otpValid && !state?.successfulActivation) && <AuthCard
                title="Nueva contraseña"
                subtitle={<p>Ingresa tu nueva contraseña</p>}
                loading={loading}
                formik={passwordFormik}
                errorMessage={errorMessage}
                form={
                    <div className="m_b_md">
                        <Input
                            title=""
                            name="password"
                            type="password"
                            placeholder="Ingresa tu contraseña"
                            onBlur={passwordFormik.handleBlur}
                            value={passwordFormik.values.password}
                            onChange={passwordFormik.handleChange}
                            error={!!passwordFormik.errors.password && !!passwordFormik.touched.password}
                            subtitle={passwordFormik.touched.password ? passwordFormik.errors.password : ""}
                        />
                        <Input
                            title=""
                            name="passwordConfirmation"
                            type="password"
                            placeholder="Confirma tu contraseña"
                            onBlur={passwordFormik.handleBlur}
                            value={passwordFormik.values.passwordConfirmation}
                            onChange={passwordFormik.handleChange}
                            error={!!passwordFormik.errors.passwordConfirmation && !!passwordFormik.touched.passwordConfirmation}
                            subtitle={passwordFormik.touched.passwordConfirmation ? passwordFormik.errors.passwordConfirmation : ""}
                        />
                    </div>
                }
                buttonTitle="Entrar"
                buttonAction={() => {}}
                link={errorMessage ? {
                    title: "",
                    buttonTitle: "Reenviar código",
                    buttonAction: () => {
                        setErrorMessage(undefined);
                        sendOTP(state.curp ?? "");
                        otpFormik.setFieldValue("otp", "");
                        passwordFormik.setFieldValue("password", "");
                        passwordFormik.setFieldValue("passwordConfirmation", "");
                    }
                }: undefined}
            />}

            {(state?.otpValid && state?.successfulActivation) && <AuthCard
                title="Contraseña modificada"
                subtitle={<p>La constraseña ha sido modificada exitosamente.</p>}
                buttonTitle="Iniciar sesión"
                buttonAction={() => navigate("/ingresar")}
            />}

            <Modal open={showCurpModal} setOpen={setShowCurpModal} className="">
                <Typography scale="large" weight="400" className="m_b_sm text_center">
                    El CURP proporcinado <strong>no se encuentra registrado</strong>.
                </Typography>
                <Typography scale="small" weight="400" className="m_b_xl text_center">
                    Para continuar, debes diligenciar el formulario de solicitud de crédito
                </Typography>
                <Link
                    to="/financia-tu-moto"
                    className=""
                    >
                    <Button
                        variant="principal"
                        scale="small"
                        className="w_100_per"
                    >
                        Solicita tu crédito ya
                    </Button>
                </Link>
            </Modal>
        </div>
    </div>;
};
