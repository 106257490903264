import { useFormikContext } from "formik";


const useValidateStep = (step: any, validateStepFunction: any) => {

  const { values } = useFormikContext();
  return validateStepFunction(step, values);
};

export default useValidateStep;
