/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMediaQuery } from "@material-ui/core";
import { Typography } from "design_system/src";
import ReactGA from "react-ga4";
import * as yup from "yup";
import { FormikProvider, useFormik } from "formik";

import { getQueryParams } from "helpers/queryParams";
import { useSelector } from "react-redux";
import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";

import { FinancialFormStatus } from "models/financialForm.interface";
import { OzonerActivityOptions } from "enums/financialFormFields.enum";

import { fetchGetTempToken } from "helpers/fetchMiCuenta";

import { logout } from "store/actions/user";
import { formatCity } from "helpers/formatCity";
import { useNavigate } from "react-router-dom";


import { RootState, store } from "../../store";
import { fetchFormCrediticio } from "../../helpers/fetchFormCrediticio";
import {
  useCreditVerificationSelectedCreditTime,
  useCreditVerificationSelectedVehicle,
} from "../../hooks/useCreditVerificationSelectedVehicle";
import {
  filterByCreditCalculation,
  getMaximumCreditNumber,
} from "./results/filterByCreditCalculation";
import { useUser } from "../../hooks/useUser";
import {
  StepFour,
  StepFourAndHalf,
  StepOne,
  StepOneAndHalf,
  StepThree,
  StepThreeAndHalf,
  StepTwo,
  StepZero,
} from "./steps";
import {
  fetchCheckIn,
  fetchValidateCurp,
  fetchValidateOzoner,
} from "../../helpers/fetchCheckIn";

import { IStepperItem } from "./context/context";
import { ModalConfirmAddress } from "./modalConfirmAddress/ModalConfirmAddress";
import SimpleStepper from "./SimpleStepper/SimpleStepper/SimpleStepper";
import BreadCrumb from "./breadcrumb/Breadcrumb";
import CTASection from "./CTASection/CTASection";


export interface CalculationStepperFormValues {
  checkInStep: {
    email: string;
    authDataTreatment: boolean;
  },
  userInfoStep: {
    name: string;
    firstLastName: string;
    secondLastName: string;
    email: string;
    phone: string;
    fullAddress: {
      cp: string;
      state: string;
      delegation: string;
      neighborhood: string;
      street: string;
      intNumber?: number;
      extNumber?: number;
      lat?: number;
      long?: number;
    };
    gender: string;
    curp: string;
    day: string;
    month: string;
    year: string;
    age?: number | string;
  };
  userAddressStep?: any;
  incomeStep: {
    monthlyIncome: number;
    monthlySpending: number;
  };
  dependantsStep: {
    childrenCount: number;
    dependantsCount: number;
    economicActivity: {
      value: string;
      description: string;
    };
    position: string;
    companyName: string;
    educationalLevel: string;
  };
  dependantsStep1?: any;
  userStatusStep: {
    civilStatus: string;
    livesWith: string[];
    assets: string[];
    otherAsset: string;
  };
  userStatusStep1?: any;
  workInDigitalPlatforms?: boolean;
  _id: string;
  status?: string;
}

const curpRegex =
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

const validationSchema = yup.object().shape({
  checkInStep: yup.object().shape({
    email: yup
    .string()
    .email("Ingrese un correo valido")
    .required("Campo requerido"),
    authDataTreatment: yup
    .bool()
    .required("Campo requerido")
    .test(
      "is-true",
      "El valor debe ser siempre true",
      (value) => value === true
    ),
  }),
  userInfoStep: yup.object().shape({
    name: yup.string().required("Campo requerido"),
    firstLastName: yup.string().required("Campo requerido"),
    secondLastName: yup.string().required("Campo requerido"),
    phone: yup
      .string()
      .required("Campo requerido")
      .matches(/^!*(\d!*){10,}$/, "Numero invalido"),
    curp: yup
      .string()
      .required("Campo requerido")
      .matches(curpRegex, "CURP inválido"),
  }),
  userAddressStep: yup.object().shape({
    fullAddress: yup.object().shape({
      cp: yup
        .string()
        .required("Debe ingresar un codigo postal")
        .matches(/^\d{5}$/, "Debe ser un número de 5 dígitos."),
      state: yup.string().required("Campo requerido"),
      delegation: yup.string().required("Campo requerido"),
      neighborhood: yup.string().required("Campo requerido"),
      street: yup.string().required("Campo requerido"),
      extNumber: yup.number().min(0, "Minimo cero").required("Campo requerido"),
      intNumber: yup.number().min(0, "Minimo cero"),
      lat: yup.number(),
      long: yup.number(),
    }),
  }),
  incomeStep: yup.object().shape({
    monthlyIncome: yup
      .number()
      .required("Campo requerido")
      .lessThan(100001, "Debe ser menor a $100,000.00mxn")
      .positive("Debe ser mayor a $0.00mxn")
      .integer("Debe ser mayor a $0.00mxn"),
    monthlySpending: yup
      .number()
      .required("Campo requerido")
      .lessThan(100001, "Debe ser menor a $100,000.00mxn")
      .positive("Debe ser mayor a  $0.00mxn")
      .integer("Debe ser mayor a $0.00mxn"),
  }),
  dependantsStep: yup.object().shape({}),
  dependantsStep1: yup.object().shape({
    educationalLevel: yup.string().required("Campo requerido"),
    economicActivity: yup.object().shape({
      value: yup.string().required("Campo requerido"),
      description: yup.string().test("", "Campo requerido", function (value) {
        if (this.parent.value === "otro") { return value ? value?.trim().length > 0 : false; }
        return true;
      }),
    }),
  }),
  userStatusStep: yup.object().shape({
    civilStatus: yup.string().required("Campo requerido"),
    livesWith: yup.array().of(yup.string()).min(1, "Al menos uno requerido"),
  }),
  userStatusStep1: yup.object().shape({
    assets: yup.array().of(yup.string()).min(1, "Campo requerido"),
  }),
  assetsStep: yup.object().shape({
    assets: yup.array().of(yup.string()),
    otherSelected: yup.boolean(),
    other: yup.string().when("otherSelected", {
      is: true,
      then: yup.string().required("Campo requerido"),
    }),
  }),
  workInDigitalPlatforms: yup.bool(),
});


export type StepsKeys = keyof typeof validationSchema.fields;

export const validateStep = (
  step: StepsKeys,
  values: CalculationStepperFormValues
) =>
  // @ts-ignore
  yup.reach(validationSchema, step).isValidSync(values[step]);

const CreditCalculationStepperView = () => {
  const breakpoint = 900;
  const matchesMD = useMediaQuery(`(min-width:${breakpoint}px)`);
  const formRef = useRef<HTMLDivElement>(null);
  const { alliedCompany } = getQueryParams(window.location.search);
  const { city } = useSelector((state: RootState) => state.cityReducer);
  const navigate = useNavigate();
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const selectedVehicle = useCreditVerificationSelectedVehicle();
  const selectedCreditTime = useCreditVerificationSelectedCreditTime();
  const [activeStep, setActiveStep] = useState(0);
  // MAPBOX
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const handleClose = () => setOpenConfirmation(!openConfirmation);

  const handleActiveFinancialForm = (exist: boolean, financialFormStatus: any, email: string) => {
    if (exist && financialFormStatus === FinancialFormStatus.REJECTED) {
      navigate("/rechazo-solicitud", {state:{ email }});
      return;
    }

    if (exist && financialFormStatus === FinancialFormStatus.PENDING) {
      navigate(`/documentos-en-proceso?email=${encodeURIComponent(email)}`);
      return;
    }

    if (exist && financialFormStatus && financialFormStatus !== FinancialFormStatus.PENDING) {
      navigate("/solicitud-en-proceso", {state:{
        email,
        status: financialFormStatus
      }});
    }
  };

  const onSubmitForm = useCallback(
    async (values: CalculationStepperFormValues) => {
      try {
        const { workInDigitalPlatforms } = values;
        if (!`${values.userInfoStep.phone}`.startsWith("521")) {
          // eslint-disable-next-line no-param-reassign
          values.userInfoStep.phone = `521${values.userInfoStep.phone}`;
        }

        const prepareFormValues = {
          checkInStep: {
            ...values.checkInStep,
          },
          userInfoStep: {
            ...values.userInfoStep,
            fullAddress: values.userAddressStep?.fullAddress,
            email: values.checkInStep.email,
          },
          incomeStep: {
            ...values.incomeStep,
          },
          dependantsStep: {
            ...values.dependantsStep,
            economicActivity: {
              value: values.dependantsStep1?.economicActivity.value,
              description: values.dependantsStep1?.economicActivity.description
            },
            companyName: values.dependantsStep1?.companyName,
            educationalLevel: values.dependantsStep1?.educationalLevel,
          },
          userStatusStep: {
            ...values.userStatusStep,
            assets: values.userStatusStep1?.assets,
            otherAsset: values.userStatusStep1?.otherAsset,
          },
          _id: values._id,
        };

        setLoading(true);
        await fetchCheckIn({
          email: prepareFormValues.checkInStep.email,
          name: `${prepareFormValues.userInfoStep.name} ${prepareFormValues.userInfoStep.firstLastName} ${prepareFormValues.userInfoStep.secondLastName}`,
          phone: `${prepareFormValues.userInfoStep.phone}`,
          curp: prepareFormValues.userInfoStep.curp,
        });

        const newFinancialForm = await fetchFormCrediticio({
          creditCalculation: prepareFormValues,
          digitalPlatforms: {
            authDataTreatment: true,
            platforms: [],
            workInDigitalPlatforms: workInDigitalPlatforms || false,
          },
          vehicleId: selectedVehicle?.internalId,
          creditTime: selectedCreditTime,
          advancedMoney: selectedVehicle?.advancedMoney,
          approved: selectedVehicle
            ? filterByCreditCalculation(getMaximumCreditNumber(values))(
                selectedVehicle
              )
            : undefined,
          score: getMaximumCreditNumber(values),
          city: formatCity(city),
          lat: values.userAddressStep.fullAddress.lat,
          long: values.userAddressStep.fullAddress.long,
        });

        if (newFinancialForm.status === FinancialFormStatus.REJECTED) {
          navigate("/rechazo-solicitud", {state:{ email: values.checkInStep.email }});
          return;
        }
        store.dispatch(logout());
        fetchGetTempToken(newFinancialForm.userInfoStep.email).then((data) => {
            if (data.financialFormId) {
                navigate(`/financia-tu-moto/documentos/${data.financialFormId}`);
            }
        });

      } catch (e: any) {
        console.error(e);
        if (e.statusCode === 409) {
          const status = e.message.split(" ")[0];
          if (Object.values(FinancialFormStatus).includes(status)) {
            handleActiveFinancialForm(true, status, values.checkInStep.email);
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [ user]
  );

  const form = useFormik<CalculationStepperFormValues>({
    initialValues: {
      checkInStep: {
        email: "",
        authDataTreatment: false,
      },
      userInfoStep: {
        name: "",
        firstLastName: "",
        secondLastName: "",
        phone: "",
        fullAddress: {
          cp: "",
          state: "",
          delegation: "",
          neighborhood: "",
          street: "",
          intNumber: undefined,
          extNumber: undefined,
          lat: undefined,
          long: undefined,
        },
        email: "",
        curp: "",
        gender: "",
        day: "",
        month: "",
        year: "",
      },
      userAddressStep: {
        curp: "",
        email: "",
        gender: "",
        day: "",
        month: "",
        year: "",
      },
      incomeStep: {
        monthlyIncome: 0,
        monthlySpending: 0,
      },
      dependantsStep: {
        childrenCount: 0,
        dependantsCount: 0,
        economicActivity: {
          value: "",
          description: "",
        },
        position: "",
        companyName: "",
        educationalLevel: "",
      },
      dependantsStep1: {
        economicActivity: {
          value: alliedCompany ? OzonerActivityOptions.DELIVERY_DRIVER : "",
          description: "",
        },
        companyName:alliedCompany ?? "",
        educationalLevel: "",
      },
      userStatusStep: {
        civilStatus: "",
        livesWith: [],
        assets: [],
        otherAsset: "",
      },
      workInDigitalPlatforms: alliedCompany !== undefined,
      _id: "",
    },
    validationSchema,
    onSubmit: onSubmitForm,
  });

  const checkinFunction = async () => {
    setLoading(true);
    const {exist, financialFormStatus} = await fetchValidateOzoner({ email: form.values.checkInStep.email });
    handleActiveFinancialForm(exist, financialFormStatus, form.values.checkInStep.email);
    setLoading(false);
  };

  const curpFunction = async () => {
    setLoading(true);
    const { values } = form;
    const exist = await fetchValidateCurp(values.userInfoStep.curp);
    setLoading(false);
    return exist;
  };

  const renderModalTitle = () => (
    <Typography scale="medium" weight="400" textColor="primary_300">
      Confirmar Ubicación
    </Typography>
  );

  const stepComponentsArr: Array<IStepperItem>[] = [
    [
      {
        component: <StepZero />,
        stepTitle: "Inicia tu proceso",
        validationKey: "checkInStep",
        functionStep: checkinFunction
      }
    ],
    [
      {
        component: <StepOne />,
        stepTitle: "Información personal",
        validationKey: "userInfoStep",
        functionStep: curpFunction
      },
      {
        component: <StepOneAndHalf />,
        validationKey: "userAddressStep",
        stepModalConfig: {
          widthDesktop: 50,
          heightDesktop: 90,
          icon: <Moto />,
          handleClose,
          title: renderModalTitle(),
          subtitle: <ModalConfirmAddress />,
        },
      },
    ],
    [
      {
        component: <StepTwo />,
        stepTitle: "Ingresos y gastos",
        validationKey: "incomeStep",
      },
    ],
    [
      {
        component: <StepThree />,
        stepTitle: "Dependientes y empleo",
        validationKey: "dependantsStep",
      },
      {
        component: <StepThreeAndHalf />,
        validationKey: "dependantsStep1",
      },
    ],
    [
      {
        component: <StepFour />,
        stepTitle: "Estado civil y Activos",
        validationKey: "userStatusStep",
      },
      {
        component: <StepFourAndHalf />,
        validationKey: "userStatusStep1",
      },
    ],
  ];

  useEffect(() => {
    ReactGA.event("VIEW_Financial_email", {
      category: "VIEW_Financial_email",
      label: "page view to the email",
    });
  }, []);

  const scrollToForm = () => {
    if (formRef && formRef.current) {
      window.scrollTo({ top: formRef.current.getBoundingClientRect().top - 50, behavior: "smooth" });
    }
  };

  return (
    <div>
      <BreadCrumb
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        stepperComponents={stepComponentsArr}
      />
      <FormikProvider value={form}>
        <div
          style={{
            flexDirection: matchesMD ? "row" : "column-reverse"
          }}
          className={
            `display_flex flex_align_center credit-calculation m_y_xxl${!matchesMD && activeStep > 0 ? " flex_gap_xxl" : " m_y_xl flex_gap_md"}`
          }
        >
          <CTASection breakpoint={breakpoint} onClick={() => scrollToForm()} />

          <div className="card-container dso_card" ref={formRef}>
              <SimpleStepper
                validateStep={validateStep}
                stepperComponents={stepComponentsArr}
                onSubmitForm={form.submitForm}
                loading={loading}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                startTextButton="Comenzar"
              />
              <div className="p_y_xs p_x_md">
                <Typography
                  scale="xsmall"
                  weight="400"
                  className="text_center"
                >
                  *Sí detectamos información{" "}
                  <span className="text_red_300">falsa</span> o{" "}
                  <span className="text_red_300">engañosa</span> tu solicitud
                  será inmediatamente{" "}
                  <span className="text_red_300">rechazada</span>.
                </Typography>
              </div>
            </div>
        </div>
      </FormikProvider>
    </div>
  );
};

export default CreditCalculationStepperView;
