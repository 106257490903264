// eslint-disable-next-line no-shadow,no-unused-vars
export enum FinancialFormStatus {
  PENDING = "pending",

  IN_VALIDATION = "inValidation",
  APPROVED = "approved",
  REJECTED = "rejected",

  SCHEDULED = "scheduled",
  COMPLETED = "completed",

  EXPIRED = "expired",
  CANCELED = "canceled",
}
