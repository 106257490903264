/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { Dispatch, useState, ReactElement } from "react";

export interface IStepperItem {
  component: ReactElement<any, any>;
  validationKey?: string;
  stepTitle?: string;
  functionStep?: (values: any) => any;
  stepModalConfig?: {
    widthDesktop?: number;
    heightDesktop?: number;
    icon: React.ReactElement<any, any> | null;
    handleOpen?: any;
    handleClose: any;
    title: JSX.Element;
    subtitle: JSX.Element;
  };
}

export enum CreditPhases {
  DigitalPlatformsForm,
  CreditForm,
}

interface CreditContextType {
  phase: CreditPhases;
  setPhase: Dispatch<React.SetStateAction<CreditPhases>>;
}

const CreditContext = React.createContext<CreditContextType>({
  phase: CreditPhases.DigitalPlatformsForm,
  setPhase: () => {}
});

const CreditProvider: React.FC = ({ children }) => {
  const [phase, setPhase] = useState(CreditPhases.DigitalPlatformsForm);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CreditContext.Provider value={{ phase, setPhase }}>
      {children}
    </CreditContext.Provider>
  );
};

// CREDIT FORM STEPS
export enum CreditFormSteps {
  checkInStep,
  userInfoStep,
  incomeStep,
  dependantsStep,
  userStatusStep,
}

export { CreditContext, CreditProvider };
