/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum FinancialFormDocs {
    INE_FRONT="ine_front",
    INE_BACK="ine_back",
    SELFIE="selfie",
    PROOF_OF_ADDRESS = "proof_of_address",
    BANK_CERTIFICATE_1 = "bank_certificate_1",
    BANK_CERTIFICATE_2 = "bank_certificate_2",
    BANK_CERTIFICATE_3 = "bank_certificate_3",
    RESERVATION_RECEIPT = "layawayReceipt",
}