/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum VEHICLE_STATUS {
  PREREGITERED = "preregitered",
  RECEIVED = "received",
  APPROVED = "approved",
  AVAILABLE = "available",
  UNAVAILABLE = "unavailable",
  RENTED = "rented",
  TESTING = "testing",
}
