import { toast } from "react-toastify";

export type Theme = "light" | "dark" | "colored";
// eslint-disable-next-line no-shadow
export enum ToasterOptions {
  info = "info",
  success = "success",
  warning = "warning",
  error = "error",
}


interface IToasterOptionsParams {
  msg: string;
  toasterType: ToasterOptions;
  style?: Theme | undefined;
  onOpenCallback?: any;
  onCloseCallback?: any;
}

export const toasterNotification = (options: IToasterOptionsParams) => {
  const {
    msg,
    toasterType,
    style = "colored",
    onOpenCallback = null,
    onCloseCallback = null,
  } = options;
  switch (toasterType) {
    case toasterType:
      return toast[toasterType](msg, {
        position: "top-center",
        autoClose: 750,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: style,
        onOpen: onOpenCallback,
        onClose: onCloseCallback, // ({ uid }) => window.alert(uid)
      });
    default:
      return toast(msg, {
        position: "top-center",
        autoClose: 750,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: style,
        onOpen: onOpenCallback,
        onClose: onCloseCallback,
      });
  }
};
