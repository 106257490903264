/* eslint-disable default-param-last */
import _ from "lodash";
import { ListVehicles, TVehicle } from "models/vehicle.interface";
import { SALE_PRICE_WEEKS, VEHICLE_SORT_TYPE } from "models/enums/vehicle.enums";
import { types } from "../types/types";
import { Cities } from "./cityReducer";

export type OrderType = `${VEHICLE_SORT_TYPE}`

export type SelectedFilters = {
  page: number,
  pages: number,
  limit: number,
  city: Cities,
  brand: string,
  model: string,
  year: number,
  cylinders: number,
  mileages: number[],
  color: string,
  salePrice: number[],
  weeks: number[],
  newVehicle: string,
  searchText: string,
  sort: OrderType,
}

export interface VehiclesReducerState {
  data: ListVehicles;
  loading: boolean;
  fetched: boolean,
  timestamp: number | null;
  error: string | null;
  city?: Cities;
  selectedFilters?: SelectedFilters;
}

export const WEEKS_STATE: SALE_PRICE_WEEKS[] = [
  SALE_PRICE_WEEKS.WEEKS_52,
  SALE_PRICE_WEEKS.WEEKS_78,
  SALE_PRICE_WEEKS.WEEKS_104,
  SALE_PRICE_WEEKS.WEEKS_156,
];

export const SALE_PRICE_STATE = [1];

const INITIAL_STATE: VehiclesReducerState = {
  data: { filters: {}, vehicles: [], homeVehicles: [], homeBrands: [], page: 1, pages: 1, count: 0 },
  loading: false,
  fetched: false,
  timestamp: null,
  error: null,
};

export const vehiclesReducer = (
  state: VehiclesReducerState = INITIAL_STATE,
  action: { type: Partial<typeof types>; payload: any }
) => {
  switch (action.type) {
    case types.vehiclesFetch:
      return { ...state, loading: true, fetched: false, data: state.data };
    case types.vehiclesFetchHomeSuccess:
      return {
        ...state,
        loading: false,
        fetched: true,
        data: {
          ...state.data,
          homeVehicles: action.payload.vehicles,
          homeBrands: action.payload.filters.brands
        }
      };
    case types.vehiclesFetchSuccess:
      return { ...state, loading: false, fetched: true, data: { ...state.data, ...action.payload } };
    case types.vehiclesFetchFail:
      return { ...state, loading: false, error: action.payload };
    case types.vehiclesFetchUpdateTimestamp:
      return { ...state, timestamp: Date.now() };
    case types.vehiclesFetchUpdateCity:
      return { ...state, city: action.payload };
    case types.vehiclesUpdateSelectedFilter:
      return {
        ...state,
        selectedFilters: _.omitBy({
          ...state.selectedFilters,
          [action.payload.key]: action.payload.value
        }, _.isUndefined)
      };
    case types.vehiclesSetSelectedFilter:
      return {
        ...state,
        selectedFilters: action.payload.filters
      };
    case types.vehiclesClearSelectedFilter:
      return { ...state, selectedFilters: "" };
    default:
      return state;
  }
};
