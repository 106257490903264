/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useLayoutEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetchOzonerGeolocation } from "helpers/fetchGeolocation";
import { getOzonerLocation } from "helpers/getOzonerLocation";
import { fetchGetFinancialForm } from "helpers/fetchMiCuenta";
import { transformFinancialFormObj, useFinancialForm } from "hooks/useGetFinancialForm";
import useAuth from "hooks/useAuth";
import { store } from "store";

export const MiCuentaRoute= () => {
    const { isAuthenticated, loading } = useAuth();
    const [financialForm, setFinancialForm] = useFinancialForm();
    const location = useLocation();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (!loading) {
            if (isAuthenticated && location.pathname === "/mi-cuenta") {
                getOzonerLocation().then((coords) => {
                    fetchOzonerGeolocation(coords);
                });
            }
            if (financialForm) {
                fetchGetFinancialForm(financialForm._id)
                    .then((data) => {
                        setFinancialForm(transformFinancialFormObj(data.ozoner));
                    });
            }
        }
    }, [loading]);

    if (loading) return null;

    return isAuthenticated ? (
            <Outlet/>
        ) : (
            <Navigate replace to="/ingresar"/>
        );
};
