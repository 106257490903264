import { gql } from "@apollo/client";

export const GET_VEHICLES = gql`
  query GetVehicles(
    $status: String!,
    $limit: Float!,
    $page: Float
    $city: String!,
    $brand: String,
    $model: String,
    $year: Float,
    $cylinders: String,
    $mileages: [Float!],
    $color: String,
    $salePrice: [Float!],
    $weeks: [Float!],
    $newVehicle: String,
    $searchText: String,
    $sort: String,
  ) {
    getAllVehicles(
      status: $status
      limit: $limit
      page: $page
      city: $city
      brand: $brand
      model: $model,
      year: $year,
      cylinders: $cylinders,
      mileages: $mileages,
      color: $color,
      salePrice: $salePrice,
      weeks: $weeks,
      newVehicle: $newVehicle,
      searchText: $searchText,
      sort: $sort,
    ) {
      count
      pages
      page
      filters {
        models
        years
        cc
        colors
        mileageRange
        priceRange
      }
      data {
        internalId
        newVehicle
        platedVehicle
        isPresale
        city {
          name
        }
        details {
          milage
          year
        }
        discounts {
          status
          netValue
          percentageValue
          type
        }
        salePrices {
          weeks
          paymentWeek
        }
        images {
          url
        }
        brand {
          name
        }
        model {
          name
        }
        cylindersCapacity
      }
    }
  }
`;
