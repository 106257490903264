import { PandaboardApiInstance } from "./api";

export const fetchCURP = async (curp: string) =>
  PandaboardApiInstance
    .post("auth-client/validate-ozoner", {curp})
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchLogin = async (curp: string, password: string) =>
  PandaboardApiInstance
    .post("auth-client/login", { curp, password })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchLogout = async () =>
  PandaboardApiInstance
    .get("auth-client/logout", { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchOtpPassword = async (curp: string) =>
  PandaboardApiInstance
    .post(`auth-client/otp/${curp}`)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchOtpValidation = async (curp: string, otp: string) =>
  PandaboardApiInstance
    .post("auth-client/otp/validate", { curp, otp })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchChangePassword = async (password: string, confirmPassword: string) =>
  PandaboardApiInstance
    .post("auth-client/reset-password", { password, confirmPassword}, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchGetSellResponsible = async (ozonerId: string, financialFormId: string) =>
  PandaboardApiInstance
    .post("sales/assign-seller", { ozonerId, financialFormId }, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchGetFinancialForm = async (formId: string) =>
  PandaboardApiInstance
    .get(`financial-form/${formId}`, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchUpdateFinancialForm = async (formId: string, payload: any) =>
  PandaboardApiInstance
    .put(`financial-form/wp/${formId}`, payload, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchGetTempToken = async (email: string) =>
  PandaboardApiInstance
    .post("auth-client/temp-token", { email }, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchGetIdCredit = async (idOzoner: string) =>
  PandaboardApiInstance
    .get(`credit/ozoner/${idOzoner}`)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchGetCreditTotals = async (id: string) =>
  PandaboardApiInstance
    .get(`credit/${id}/totals`)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });
