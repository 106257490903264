/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";

export const NoAuthRoute= () => {
    const { isAuthenticated, loading } = useAuth();

    if (loading) return null;

    return isAuthenticated ? (
            <Navigate replace to="/mi-cuenta"/>
        ) : (
            <Outlet/>
        );
};
