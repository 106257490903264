export const SOCIAL_MEDIA = {
  CO: {
    facebook: "https://www.facebook.com/Ozon.mobi",
    twitter: "https://twitter.com/Ozonmobi",
    instagram: "https://www.instagram.com/ozon.mobi",
  },
  MX: {
    facebook: "https://www.facebook.com/Ozonmx-105042201430030",
    twitter: "https://twitter.com/ozon_mx",
    instagram: "https://www.instagram.com/ozon.mx",
    tiktok: "https://www.tiktok.com/@ozon_mx"
  },
};
