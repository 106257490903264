/* eslint-disable no-shadow */
import { useCallback, useContext, useEffect } from "react";
import { StepperContext } from "../Context/context";
import {
  DECREMENT_CURRENT_STEP,
  INCREMENT_CURRENT_STEP,
  SET_CURRENT_STEP,
  SET_STEPS,
} from "../Store/store";

const useStepper = () => {
  const { currentStep, steps, dispatch } = useContext(StepperContext);

  if (!StepperContext) {
    throw new Error("useStepper should be used inside StepperProvider");
  }

  const incrementCurrentStep = useCallback(() => {
    dispatch({
      type: INCREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const decrementCurrentStep = useCallback(() => {
    dispatch({
      type: DECREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const setCurrentStep = useCallback(
    (index: number) => {
      dispatch({
        type: SET_CURRENT_STEP,
        payload: index,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (steps?.length && currentStep > steps.length - 1) {
      setCurrentStep(steps.length - 1);
    }
  }, [currentStep, setCurrentStep, steps.length]);

  const setSteps = useCallback(
    (steps) => dispatch({ type: SET_STEPS, payload: { steps } }),
    [dispatch]
  );

  return {
    incrementCurrentStep,
    decrementCurrentStep,
    setSteps,
    currentStep,
    setCurrentStep,
    steps,
  };
};

export default useStepper;
