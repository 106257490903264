/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
// Icons
import { ReactComponent as Downfill } from "../../../static/icons/chevron-down.svg";
import { ReactComponent as Upfill } from "../../../static/icons/chevron-up.svg";
import { ReactComponent as Down } from "../../../static/icons/down-sm.svg";
import { ReactComponent as Up } from "../../../static/icons/up-sm.svg";
import { ReactComponent as Close } from "../../../static/icons/close-sm.svg";
import { ReactComponent as Checkbox } from "../../../static/icons/checkmark.svg";
import "./select.scss";
import { Button, Popup, Typography } from "../..";


interface ISimpleOption {
  label: string;
  customComponent?: any;
  value: any;
  selected?: boolean;
  options?: undefined;
  partSelected?: undefined;
  prev?: boolean;
}
interface ICompoundOption {
  label: string;
  customComponent?: any;
  value?: undefined;
  selected?: boolean;
  options: ISimpleOption[];
  partSelected?: boolean;
  prev?: boolean;
}
interface ICompoundOptionGen {
  label: string;
  value?: undefined;
  selected?: boolean;
  options: (string | ISimpleOption)[];
  partSelected?: boolean;
}
export type IOption = string | ISimpleOption | ICompoundOptionGen;

interface ISelectBase {
  name?: string;
  value?: any;
  onChange?: any;
  onBlur?: any
  disabled?: boolean;
  error?: any;
  className?: string;
  placeholder?: string;
  fixedPlaceholder?:boolean;
}

interface ISelectCheckbox {
  options: (string | ISimpleOption | ICompoundOptionGen)[];
  variant?: "checkbox";
  multiple?: false;
  setFilter?: any;
}
interface ISelectMultiple {
  options: (string | ISimpleOption)[];
  variant?: "default";
  multiple: true;
  setFilter?: any;
}
interface ISelectSingle {
  options: (string | ISimpleOption)[];
  variant?: "default";
  multiple?: false;
  setFilter?: any;
}
interface ISelectButton {
  options: (string | ISimpleOption)[];
  variant?: "button";
  multiple?: false;
  setFilter?: undefined;
}


export type ISelect = ISelectBase & (ISelectCheckbox | ISelectMultiple | ISelectSingle | ISelectButton);


const Select: React.FC<ISelect> = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  fixedPlaceholder=false,
  options,
  variant = "default",
  disabled,
  error,
  className,
  multiple,
  setFilter,
}) => {
  const [selected, setSelected] = useState<(ISimpleOption | ICompoundOption)[]>([]);
  const [filter, setFilters] = useState("");
  const [open, setOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState<number | undefined>();
  const [isNested, setIsNested] = useState(false);
  const [prevOptions, setPrevOptions] = useState<(ISimpleOption | ICompoundOption)[]>([]);
  const [oldValues, setOldValues] = useState<any[]>([]);
  const [active, setActive] = useState<boolean>(false);
  const [optionsRender, setOptionsRender] = useState<any>();


  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as any)) {
      setActive(false);
      setOpen(false);
      if (onBlur)
        onBlur({ target: { name, value } });
      // aquí se puede cerrar el dropdown
    }
    else {
      setActive(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  useEffect(() => {
    if (setFilter)
      setFilter(filter);
  }, [filter]);

  const findOldValues = (left: any[]) => {
    const find = left.map(v => {
      const filt = prevOptions.filter((opt: any) => _.isEqual(opt.value, v.value));
      return filt.length ? filt[0] : { value: v.value, label: v.value };
    });
    setOldValues(find);
  };

  const selectGroup = (group: ICompoundOption) => {
    const opts = group.options.map(opt => opt.value);
    if (value && Array.isArray(value)) {
      const res = [...value];
      if (group.selected) {
        opts.filter(opt => res.filter((v: any) => _.isEqual(opt, v)).length)
          .forEach(opt => {
            res.splice(res.indexOf(opt), 1);
          });
      }
      else {
        opts.filter(opt => !(res.filter((v: any) => _.isEqual(opt, v)).length))
          .forEach(opt => {
            res.push(opt);
          });
      }
      onChange({ target: { name, value: res } });
    }
    else {
      onChange({ target: { name, value: opts } });
    }
  };

  const select = (val: any, deletes: boolean = false) => {
    setFilters("");
    // if (variant === "default") {
    if (Array.isArray(val) && deletes) {
      if ((variant === "checkbox" || variant === "button") && value && Array.isArray(value)) {
        const res = [...value];
        val.forEach(va => {
          if (value.filter((v: any) => _.isEqual(va, v)).length) {
            res.splice(res.indexOf(va), 1);
          }
          else {
            res.push(va);
          }
        });
        onChange({ target: { name, value: res } });
      }
    }
    else if (multiple || variant === "checkbox" || variant === "button") {
      if (value && Array.isArray(value)) {
        if (value.filter((v: any) => _.isEqual(val, v)).length) {
          const res = [...value];
          res.splice(res.indexOf(val), 1);
          onChange({ target: { name, value: res } });
        }
        else {
          onChange({ target: { name, value: [...(value), val] } });
        }
      }
      else {
        onChange({ target: { name, value: [val] } });
      }
    }
    else {
      onChange({ target: { name, value: val } });
      setOpen(false);
    }

    // }
  };

  const renderOptions = (optsIn: (ISimpleOption | ICompoundOption)[]) => {

    let showOptions = [...optsIn];
    if (!showOptions.length) {
      return (
        <li className="dso_select_list_item ">
          <Typography className="w_100_per p_x_md p_y_xs dso_select_list_label text_center" scale="small" color="neutral_600" weight="400" component="label">
            Escribe para ver resultados...
          </Typography>
        </li>
      );
    }
    if (variant === "default" && multiple) {
      showOptions = showOptions.filter(option => !option.selected);
    }
    if (filter && !setFilter) {
      showOptions = showOptions.filter((option => option.label.toLowerCase().includes(filter.toLowerCase())));
    }
    if (!showOptions.length) {
      return (
        <li className="dso_select_list_item ">
          <Typography className="w_100_per p_x_md p_y_xs dso_select_list_label text_center" scale="small" color="neutral_600" weight="400" component="label">
            No hay resultados
          </Typography>
        </li>
      );
    }
    return showOptions.map((option, i) => {

      if (variant === "default") {
        const { label, "value": val } = option;
        return (
          <li
            key={`${label}${i}`}
            className={`dso_select_list_item cursor_pointer ${option.selected ? "selected" : ""}`}>
            <Typography className="w_100_per cursor_pointer p_x_md p_y_xs dso_select_list_label" scale="small" color="neutral_600" weight="400" component="label"
            >
              <input
                type="radio"
                name={`${name}${label}_radio`}
                onChange={() => select(val)}
                checked={false}
              />

              {option.customComponent ?? label}
            </Typography>
          </li>
        );
      }
      if (variant === "checkbox" || variant === "button") {
        const { label, "value": val, "options": opts } = option;
        if (opts !== undefined) {
          return (
            <React.Fragment key={`${label}${i}`}>
              <li

                className="dso_select_list_item dso_select_group_title cursor_pointer display_flex flex_gap_xs flex_align_center">
                <Typography className="flex_grow_1 w_100_per cursor_pointer p_x_md p_y_xs  dso_select_list_label  display_flex flex_gap_xs flex_align_center" scale="small" color="neutral_1000" weight={option.selected ? "600" : "400"} component="label">
                  <div className={`dso_select_checkbox br_xxxs p_xxxs ${option.selected ? "selected" : ""} ${option.partSelected ? "part_selected" : ""}`}>
                    <Checkbox className="dso_input_checkbox_icon" />
                  </div>
                  <input
                    type="checkbox"
                    name={`${name}${label}_radio`}
                    onChange={() => selectGroup(option)}
                  />
                  {option.customComponent ?? label}
                </Typography>
                <div className="dso_select_group_arrow cursor_pointer display_flex p_x_md flex_grow_0"
                  onClick={(e) => { setOpenGroup(openGroup === i ? undefined : i); e.stopPropagation(); }}
                  role="button"
                  tabIndex={-1}
                  onKeyDown={(e) => { setOpenGroup(openGroup === i ? undefined : i); e.stopPropagation(); }}
                >
                  {openGroup === i ? (<Upfill className="dim_lg" />) : (<Downfill className="dim_lg" />)}
                </div>
              </li>
              {openGroup === i && opts?.map((opt, ix) => (
                <li
                  key={`${label}${i}${ix}`}
                  className="dso_select_list_item cursor_pointer display_flex flex_gap_xs flex_align_center">
                  <Typography className="w_100_per cursor_pointer p_l_xxl p_x_md p_y_xs dso_select_list_label display_flex flex_gap_xs flex_align_center" scale="small" color="neutral_1000" weight={opt.selected ? "600" : "400"} component="label">
                    <div className={`dso_select_checkbox br_xxxs p_xxxs ${opt.selected ? "selected" : ""}`}>
                      <Checkbox className="dso_input_checkbox_icon" />
                    </div>
                    <input
                      type="checkbox"
                      name={`${name}${opt.label}_radio`}
                      onChange={() => select(opt.value)}
                    />
                    {opt.customComponent ?? opt.label}
                  </Typography>
                </li>
              ))}
            </React.Fragment>
          );
        }
        return (
          <li
            key={`${label}${i}`}
            className={`dso_select_list_item cursor_pointer display_flex flex_gap_xs flex_align_center ${isNested ? "dso_select_group_title" : ""}`}>
            <Typography className="w_100_per cursor_pointer p_x_md p_y_xs dso_select_list_label display_flex flex_gap_xs flex_align_center" scale="small" color="neutral_1000" weight={option.selected ? "600" : "400"} component="label">
              <div className={`dso_select_checkbox br_xxxs p_xxxs ${option.selected ? "selected" : ""}`}>
                <Checkbox className="dso_input_checkbox_icon" />
              </div>
              <input
                type="checkbox"
                name={`${name}${label}_radio`}
                onChange={() => select(val)}
              />
              {option.customComponent ?? label}
            </Typography>
          </li>
        );
      }
      return (<div />);
    });

  };

  useEffect(() => {
    let nested = false;
    const selectedOption: (ISimpleOption | ICompoundOption)[] = [...options.map((val) => {
      if (typeof val === "string") {
        return { label: val, value: val };
      }
      if (val.options !== undefined) {
        const opts = val.options.map((opt) => {
          if (typeof opt === "string") {
            return { label: opt, value: opt };
          }
          return opt;
        });
        return { ...val, options: opts };
      }
      return { ...val };
    })];
    let notFoundOptions: any[] = [];

    if (Array.isArray(value) && (variant === "checkbox" || variant === "button" || multiple)) {
      notFoundOptions = (value ?? []).map((val: any) => ({ value: val, find: false }));
    }
    else if (value !== undefined && value !== "") {
      notFoundOptions = [{ value, find: false }];
    }
    selectedOption.forEach((option, i) => {
      const { label, "value": val, "options": opts } = option;
      if (variant === "default") {
        if (multiple) {
          if (Array.isArray(value) && value.filter((v) => _.isEqual(val, v)).length) {
            selectedOption[i].selected = true;
            notFoundOptions.forEach((element, idx) => {
              if (_.isEqual(val, element.value)) {
                notFoundOptions[idx].find = true;
              }
            });
          }
          else {
            selectedOption[i].selected = false;
          }
        }
        else if (_.isEqual(val, value)) {
          selectedOption[i].selected = true;
          notFoundOptions.forEach((element, idx) => {
            if (_.isEqual(val, element.value)) {
              notFoundOptions[idx].find = true;
            }
          });
        }

        else {
          selectedOption[i].selected = false;
        }
      }
      else if (variant === "checkbox" || variant === "button") {
        if (val !== undefined) {
          if (Array.isArray(value) && value.filter((v) => _.isEqual(val, v)).length) {
            selectedOption[i].selected = true;
            notFoundOptions.forEach((element, idx) => {
              if (_.isEqual(val, element.value)) {
                notFoundOptions[idx].find = true;
              }
            });
          }
          else {
            selectedOption[i].selected = false;
          }
        }
        else if (opts !== undefined) {
          nested = true;
          let partsel = false;
          let sel = true;
          opts.forEach((opt, ix) => {
            if (Array.isArray(value) && value.filter((v) => _.isEqual(opt.value, v)).length) {
              (selectedOption[i].options ?? [])[ix].selected = true;
              partsel = true;
              notFoundOptions.forEach((element, idx) => {
                if (_.isEqual(opt.value, element.value)) {
                  notFoundOptions[idx].find = true;
                }
              });
            }
            else {
              (selectedOption[i].options ?? [])[ix].selected = false;
              sel = false;
            }
          });
          selectedOption[i].selected = sel;
          selectedOption[i].partSelected = partsel;
        }
      }
    });
    findOldValues(notFoundOptions.filter(ele => !ele.find));
    setPrevOptions([...prevOptions, ...selectedOption.filter(ele => ele.selected || ele.partSelected)]);
    setIsNested(nested);
    setSelected(selectedOption);
  }, [value, options]);

  useEffect(() => {
    setOptionsRender(renderOptions(selected));

  }, [selected, filter, variant, multiple, openGroup]);

  const getPlaceholder = () => (<Typography scale="small" weight="600" textColor={fixedPlaceholder ? "neutral_900" : "neutral_600"} className="dso_select_placeholder">{placeholder}</Typography>);
  const getOptionTag = (label: string, val: any, values?: any[], complete?: boolean) => {
    let subtitle = "";
    if (values) {
      if (complete) {
        subtitle = " | Todos";
      }
      else {
        const sel = values.filter((ele) => ele.selected);
        if (sel.length > 1) {
          subtitle = ` (${sel.length})`;
        }
        else {
          subtitle = ` | ${sel[0].label}`;
        }
      }
    }
    return (
      <div className="dso_option_tag" key={`tag_${label}${subtitle}`}>
        <Typography scale="xsmall" weight="600" textColor="primary_300" className="dso_option_tag_text">{label}
          {subtitle}
        </Typography>
        <Close className="dso_option_tag_close dim_sm text_primary_300 cursor_pointer" role="button" tabIndex={-1}
          onClick={(e) => { e.stopPropagation(); if (values) { select(values.filter(ele => ele.selected).map(ele => ele.value), true); } else { select(val); } }}
          onKeyDown={(e) => { e.stopPropagation(); if (values) { select(values.filter(ele => ele.selected).map(ele => ele.value), true); } else { select(val); } }}
        />
      </div>
    );
  };
  const renderSelected = () => {
    const selecteds = selected.filter((option: any) => option.selected || option.partSelected).concat(oldValues);
    // console.log(selecteds);
    if (!selecteds.length) {
      // setViewFilter(true);
      if (!filter) {
        return getPlaceholder();
      }
      return (<div />);
    }
    if (selecteds.length && variant === "default" && !multiple) {
      // setViewFilter(false);
    }
    // if (variant === "default") {
    // if (multiple) {
    if (multiple || variant === "checkbox") {
      return [...(fixedPlaceholder ? [getPlaceholder()] : []), ...selecteds.map((ele) => {
        const { label, "value": val } = ele;
        return getOptionTag(label, val, ele.options, ele.selected);
      })];
    }
    const { label, "value": val } = selecteds[0];
    return (<Typography scale="small" weight="600" textColor="neutral_1000" className="selected_option">{label}</Typography>);
    // }
    // return (<div />);
  };


  return (
    // eslint-disable-next-line
    <div ref={dropdownRef} className={`pos_relative dso_select_cont ${className} ${disabled ? "disabled" : ""} ${error ? "error" : ""} ${active ? "active" : ""}`}>
      {variant === "button" ? (
        <Button scale="small" variant="selector" className={`${open ? "active" : ""}`} onClick={(e) => { setOpen(!open); e.stopPropagation(); }}>{`${placeholder} (${value?.length ?? 0})`}</Button>
      ) : (
        <>
          <label className="dso_select">
            {renderSelected()}
            <div className="text_transparent w_fit pos_relative p_x_xxs">
              {filter}
              <input className="dso_select_filter w_100_per" disabled={disabled} type="text" value={filter} onChange={(e) => { setOpen(true); setFilters(e.target.value); }} onFocus={() => setOpen(true)} />
            </div>
          </label>
          <div className="dso_select_arrow cursor_pointer display_flex" onClick={(e) => { setOpen(!open); e.stopPropagation(); }} role="button" tabIndex={-1} onKeyDown={() => setOpen(!open)}>
            {open ? (<Up className="dim_lg" />) : (<Down className="dim_lg" />)}
          </div>
        </>
      )}
      <Popup open={open} className="p_none dso_select_dropdown" >
        <ul className="dso_select_list_cont">
          {optionsRender}
        </ul>
      </Popup>
    </div>
  );
};

export default Select;
