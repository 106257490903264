/* eslint-disable default-param-last */
export const SET_STEPS = "SET_STEPS";
export const INCREMENT_CURRENT_STEP = "INCREMENT_CURRENT_STEP";
export const DECREMENT_CURRENT_STEP = "DECREMENT_CURRENT_STEP";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";

export interface StepperStateType {
  steps: any[];
  currentStep: number;
}

const defaultStepperState: StepperStateType = {
  steps: [],
  currentStep: 0,
};

const reducer = (state = defaultStepperState, action: any) => {
  const { currentStep, steps } = state;
  const { type, payload } = action;
  switch (type) {
    case SET_STEPS:
      return {
        ...state,
        steps: payload.steps,
      };
    case INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep:
          currentStep < steps.length - 1 ? currentStep + 1 : currentStep,
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep:
          action.payload <= steps.length ? action.payload : state.currentStep,
      };
    case DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: currentStep > 0 ? currentStep - 1 : currentStep,
      };

    default:
      return state;
  }
};

export { reducer, defaultStepperState };
