/* eslint-disable no-nested-ternary */
import { Typography } from "design_system/src";
import React, { useEffect, useState } from "react";

type optionsProps = {
  value: any;
  label: any;
}

type optionsCardProps = {
  name: string;
  isSelected?: boolean;
  withBorder?: boolean;
  onChange: any;
}

type UniqueSelectionProps = {
  name: string;
  value: any;
  options: optionsProps[];
  handleSelectChange: any;
  loading?: boolean;
  direction?: "horizontal" | "vertical",
}

const ChoiceCard:React.FC<optionsProps & optionsCardProps> = ({
  value,
  label,
  name,
  isSelected = false,
  onChange,
  withBorder = false
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isChecked, setIsChecked] = useState(isSelected);

  const handleCheckboxChange = (event: any) => {
    onChange(event.target.value);
    setIsChecked(true);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <label
      htmlFor={`${name}-${value}`}
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <input
        name={name}
        type="radio"
        value={value}
        id={`${name}-${value}`}
        style={{ position: "absolute", zIndex: -1 }}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <div
        className={
          `p_x_md p_y_xxs br_xxs border_solid border_1${
            isSelected
              ? " bg_primary_300 border_primary_300"
              : (isHovering && !isSelected)
                ? (withBorder)
                  ? " text_primary_300 border_primary_300"
                  : " text_primary_300 border_transparent"
                : (withBorder)
                  ? " bg_transparent border_neutral_400"
                  : " bg_transparent border_transparent"
          }`}>
        <Typography weight="400" scale="small">{label}</Typography>
      </div>
    </label>
  );
};

export const UniqueSelectionFilter:React.FC<UniqueSelectionProps> = ({
  name,
  value,
  options,
  handleSelectChange,
  loading = false,
  direction = "horizontal"
}) => {
  return (
    <div className={`display_flex flex_gap_sm flex_wrap${ direction === "vertical" ? " flex_col" : ""}`}>
      {options.length > 0 && options.map(({value: optionValue, label: optionLabel}, index) => (
        <ChoiceCard
          key={index}
          value={optionValue}
          label={optionLabel}
          name={name}
          isSelected={value === optionValue}
          withBorder={direction === "horizontal"}
          onChange={(newValue: any) => handleSelectChange(newValue)}
        />
      ))}
    </div>
  );
};
