import React, { FC, ReactElement } from "react";
import { useMediaQuery } from "@material-ui/core";

import { Button, Typography } from "design_system/src";

import checkedIcon from "static/icons/checked.svg";
import noCheckedIcon from "static/icons/no-checked.svg";
import arrowBtn from "static/icons/arrow-btn.svg";
import separatorActive from "static/icons/separator-active.svg";
import separatorInactive from "static/icons/separator-inactive.svg";
import largeSeparatorActive from "static/icons/large-separator-active.svg";
import largeSeparatorInactive from "static/icons/large-separator-inactive.svg";
import "./StepperItem.scss";

export type descriptionItemProps = {
    text: string;
    color?: "red_300" | "green_300";
}

export type StepperItemProps = {
    step: string;
    title: string;
    description?: descriptionItemProps;
    completed: boolean;
    active: boolean;
    enabled: boolean;
    documentsRequired?: string[];
    documents?: string[];
    component?: ReactElement<any, any>;
};

type indexProps = {
    index: number;
    lastPosition: boolean;
    action: any;
};

export const StepperItem:FC<{props: StepperItemProps, position: indexProps}> = ({
    props,
    position
}) => {
    const matchesMD = useMediaQuery("(min-width:924px)");
    const {
        step,
        title,
        description,
        completed,
        active,
        enabled,
    } = props;
    const {
        index,
        lastPosition,
        action,
    } = position;

    return <div className={`w_100_per stepperItem__container${!enabled ? " with_opacity" : ""}`}>
        <div className={`stepperItem w_100_per display_flex${description && index > 0 ? " with_description" : ""}`}>
            <div className="w_40_px display_flex flex_center">
                <img
                    src={completed ? checkedIcon : noCheckedIcon}
                    alt={`paso ${index + 1}`}
                    className="w_20_px"
                />
            </div>
            <div
                className={`stepperItem__btn flex_grow_1 display_flex flex_align_center p_xs br_xs${active && matchesMD ? " bg_primary_25 border_solid border_1 border_primary_50" : " border_solid border_1 border_transparent"}${enabled && matchesMD ? " enabled" : ""}`}
                onClick={() => {
                    if (enabled) {
                        action(step);
                    }
                }}
            >
                <div>
                    <Typography className="m_y_none" weight="400" scale="medium" component="p">
                        {title}
                    </Typography>
                    {description && <Typography
                        className={`m_y_none${description.color ? ` text_${description.color}` : ""}`}
                        weight="400" scale="small"
                        component="p"
                    >
                        {description.text}
                    </Typography>}
                </div>
            </div>
            <div className="w_35_px display_flex flex_center">
                {(enabled && !matchesMD) && <img
                    src={arrowBtn}
                    alt={`continuar paso ${index + 1}`}
                    className="w_25_px"
                    onClick={() => {
                        if (enabled) {
                            action(step);
                        }
                    }}
                />}
            </div>
        </div>
        {!lastPosition && <div className="w_40_px display_flex flex_center">
            {description && <img
                src={completed ? largeSeparatorActive : largeSeparatorInactive}
                alt={`separador paso ${index + 1}`}
                className="w_1_px stepperItem__separator with_description"
            />}
            {!description && <img
                src={completed ? separatorActive : separatorInactive}
                alt={`separador paso ${index + 1}`}
                className="w_1_px stepperItem__separator"
            />}
        </div>}
</div>;
};
