import { useState, useEffect } from "react";
import { store } from "store";
import { useNavigate } from "react-router-dom";
import { logout } from "store/actions/user";
import { useFinancialForm } from "./useGetFinancialForm";

function useAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [financialForm] = useFinancialForm();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAuthStatus = async () => {
            const { jwt, refreshToken, user } = store.getState().userReducer;
            setIsAuthenticated(jwt !== "" && refreshToken && refreshToken !== "" && user.account?.isActive === true && financialForm !== null);
            setLoading(false);
        };

        fetchAuthStatus();
    }, []);

    useEffect(() => {
        const tempToken = localStorage.getItem("tempToken");
        if (tempToken || financialForm == null) {
            store.dispatch(logout());
            navigate("/ingresar");
        }
    }, [financialForm]);

    return { isAuthenticated, loading };
}

export default useAuth;
